import * as React                          from 'react'
import Button                              from '@mui/material/Button'
import CssBaseline                         from '@mui/material/CssBaseline'
import TextField                           from '@mui/material/TextField'
import FormControlLabel                    from '@mui/material/FormControlLabel'
import Checkbox                            from '@mui/material/Checkbox'
import Link                                from '@mui/material/Link'
import Box                                 from '@mui/material/Box'
import Typography                          from '@mui/material/Typography'
import Container                           from '@mui/material/Container'
import { useApi }                          from '../utils/api'
import { useNavigate }                     from 'react-router-dom'
import { Alert, Card }                     from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { LogoPromotive }                   from '../components/Logo'
import { t }                               from 'i18next'
import useApps                             from '../hooks/useApps'
import { AppContext }                      from '../App'
import { getDefaultURL }                   from '../utils/consts'
import useRegions                          from '../hooks/useRegions'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© Copyright '}
      <Link color="inherit" href="https://coloreal.com/">
        Promotive
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {

  const [loginError, setLoginError] = useState(null)
  const { setCurrentApp, user, setUser } = useContext(AppContext)
  const [localUser, setLocalUser] = useState(user)
  const api = useApi(localUser)
  const navigate = useNavigate()
  const [_, reloadApps] = useApps()
  const [__, reloadRegions] = useRegions()

  useEffect(() => {
    (async () => {
      if (!!localUser) {
        const apps = (await api.app.list()).data
        if (apps?.length === 1) {
          setCurrentApp(apps[0])
        }
        setUser(localUser)
        reloadApps()
        reloadRegions()
        navigate(getDefaultURL(localUser.role))
      }
    })();
  }, [localUser])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    api.user.login(data.get('email'), data.get('password'))
      .then(user => {
        // local user change triggers apps load & processing
        setLocalUser(user)
      })
      .catch(error => {
        setLoginError(error)
      })
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box paddingX={6} paddingY={2}>
          <LogoPromotive />
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Card sx={{ p: 2 }}>
            <Typography component="h1" variant="h5" color={'text.secondary'}>
              {t('Sign in')}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('Email address')}
              name="email"
              autoComplete="email"
              onKeyDown={(e) => setLoginError(null)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('Password')}
              type="password"
              id="password"
              autoComplete="current-password"
              onKeyDown={(e) => setLoginError(null)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t('Remember me')}
            />
          </Card>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('Sign In')}
          </Button>
          {loginError && <Alert severity="error">{t('Unable to login, please try again.')}</Alert>}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}