import PropTypes                 from 'prop-types'
import { Box, Stack, TextField } from '@mui/material'
import React, { useState }       from 'react'
import Typography                from '@mui/material/Typography'

export default function ColorInput(props) {

  const { defaultValues, onChangeValues, partTitles, title } = props
  const [values, setValues] = useState(defaultValues?.length === partTitles.length ? [...defaultValues] : partTitles.map(it => null))
  const [focus, setFocus] = useState(false)

  const handleChange = (index) => (e) => {
    setValues(prevState => {
      let val = [...prevState]
      val[index] = e.target.value
      return val
    })
  }

  return (
    <Box>
      <Typography variant={'caption'} color={focus ? 'primary' : 'default'}>{title}</Typography>
      <Stack direction={'row'}>
        {partTitles.map((title, index) => {
          return (<TextField
            key={`input-${index}`}
            label={title}
            type="number"
            value={values[index] ?? ''}
            onChange={handleChange(index)}
            variant={'standard'}
            onBlur={() => {
              setFocus((foc) => false)
              onChangeValues(values)
            }}
            onFocus={() => {
              setFocus((foc) => true)
            }}
          />)
        })}
      </Stack>
    </Box>
  )

}

ColorInput.propTypes = {
  defaultValues: PropTypes.array.isRequired,
  onChangeValues: PropTypes.func.isRequired,
  partTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
}