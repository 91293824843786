import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel, LinearProgress, MenuItem,
  Select,
  Switch,
}                                 from '@mui/material'
import { useTranslation }         from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi }                 from '../utils/api'
import { useSnackbar }                       from 'notistack'
import { AppsCheckboxes, RegionsCheckboxes } from '../components/Checkboxes'
import BaseForm                                                    from './BaseForm'
import { USER_ROLE_APP_EDITOR, USER_ROLE_NEWS_EDITOR, USER_ROLES } from '../utils/consts'

export default function User() {

  const api = useApi()
  const navigate = useNavigate()
  const { userId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    email: '',
    role: 'app_editor',
    password: '',
    confirmPassword: '',
    isTester: false,
    appIds: [],
    regionIds: [],
  })
  const [showPasswordPanel, setShowPasswordPanel] = useState(isNaN(userId))
  const [screenInitialized, setScreenInitialized] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(userId)) {
        let user = await api.user.detail(userId)
        setValues({
          name: '',
          email: '',
          role: '',
          password: '',
          confirmPassword: '',
          isTester: false,
          ...user
        })
      }
      setScreenInitialized(true)
    })()
  }, [userId])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const onDelete = (id) => {
    api.user.delete(userId).then(() => {
      enqueueSnackbar(t('User deleted'), {
        variant: 'success'
      })
      navigate('/dashboard/users')
    }).catch(error => {
      enqueueSnackbar(t('Failed to delete user'), {
        variant: 'error'
      })
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    if (!data.name) {
      enqueueSnackbar(t('Name can not be empty'), {
        variant: 'error'
      })
      return
    }
    if (showPasswordPanel) {
      if (data.password === '') {
        enqueueSnackbar(t('Password can not be empty'), {
          variant: 'error'
        })
        return
      } else if (data.password !== data.confirmPassword) {
        enqueueSnackbar(t('Password does not match confirm password'), {
          variant: 'error'
        })
        return
      }
    } else {
      // if the password is not changed, ignore it
      delete data.password
      delete data.confirmPassword
    }
    try {
      let user = await api.user.save(data)
      navigate(`/dashboard/user/${user.id}`)
      enqueueSnackbar(t('User saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save user'), {
        variant: 'error'
      })
    }
  }

  if (screenInitialized === false) {
    return (<Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>)
  }

  return (
      <Container component="main">
        <BaseForm
          id={isNaN(userId) ? 0 : parseInt(userId)}
          formTitle={t('User')}
          values={values}
          onSubmit={onSubmit}
          onDelete={onDelete}
        >
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="user-name">{t('Name')}</InputLabel>
              <Input
                id="user-name"
                value={values.name}
                onChange={handleChange('name')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="user-email">{t('Email')}</InputLabel>
              <Input
                id="user-email"
                value={values.email}
                disabled={!isNaN(userId)}
                onChange={handleChange('email')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard">
              <InputLabel htmlFor="select-user-role">{t('Role')}</InputLabel>
              <Select
                labelId="select-user-role-label"
                id="select-user-role"
                name={'role'}
                value={values.role}
                onChange={handleChange('role')}
              >
                {USER_ROLES.map(role => (
                  <MenuItem key={role} value={role}>{t(role)}</MenuItem>
                ))}
              </Select>
            </FormControl>
            { values.role !== 'super_admin' && (<AppsCheckboxes
              selectedAppIds={values.appIds ?? []}
              onAppIdsChange={(appIds) => {
                handleChange('appIds')({ target: { value: appIds } })
              }}
            />)}
            { (values.role === USER_ROLE_APP_EDITOR || values.role === USER_ROLE_NEWS_EDITOR) && (<RegionsCheckboxes
              handleProperty={'id'}
              selectedRegionCodes={values.regionIds ?? []}
              onSelectedRegionCodesChange={(regionIds) => {
                handleChange('regionIds')({ target: { value: regionIds } })
              }}
            />)}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch
                checked={values.isTester}
                onChange={(e) => handleChange('isTester')({ target: { value: e.target.checked } })}
              />}
              label={t('Tester')} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch
                checked={showPasswordPanel}
                onChange={(e) => setShowPasswordPanel(!showPasswordPanel)}
                disabled={isNaN(userId)}
              />}
              label={t('Set password')} />
          </Grid>
          { showPasswordPanel && <>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="user-password">{t('Password')}</InputLabel>
                <Input
                  id="user-password"
                  type={'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="user-password-confirm">{t('Confirm password')}</InputLabel>
                <Input
                  id="user-password-confirm"
                  type={'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                />
              </FormControl>
            </Grid>
          </>}
        </BaseForm>
      </Container>
  )
}