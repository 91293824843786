import { useContext, useState } from 'react'
import { AppContext }                                                                       from '../App'
import { getLocalStorageApp, getLocalStorageUser, setLocalStorageApp, setLocalStorageUser } from './storage'

let currentUser = getLocalStorageUser()
let currentApp = getLocalStorageApp()

export const useCurrentUser = () => {

  const [user, setUser] = useState(currentUser)

  const setUserInternal = (usr) => {
    currentUser = usr
    setUser(usr)
    setLocalStorageUser(usr)
  }

  // return global userState state and setter function
  return [user, setUserInternal];
}

export const useCurrentApp = () => {

  const [app, setApp] = useState(currentApp)

  const setAppInternal = (app) => {
    currentApp = app
    setApp(app)
    setLocalStorageApp(app)
  }

  // return global userState state and setter function
  return [app, setAppInternal];
}

export function useApi(tmpUser = null) {

  // TODO update development url
  const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.coloreal.com' : 'https://api.coloreal.com'

  const { currentApp, user } = useContext(AppContext)

  const send = (url, options) => {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then(async (response) => {
          if (response.status / 100 !== 2) {

            reject({
              ...data,
              status: response.status,
              statusText: response.statusText,
            })
            return
          }
          let data
          try {
            data = JSON.parse(await response.text())
          } catch (e) {
            data = null
          }
          if (!!data?.errorCode) {
            reject({ ...data, statusText: data?.error })
          } else {
            resolve(data)
          }
        })
        .catch(error => {
          console.error(`Api failed on ${options.method.toUpperCase()} ${url}`, error)
          reject(error)
        })
    })
  }

  const encodeURIComponentOwn = (component) => {
    return Array.isArray(component) ? component.map(it => encodeURIComponent(it)).join(',') : encodeURIComponent(component)
  }

  const get = (path, args) => {
    let query = ''
    if (args && Object.getOwnPropertyNames(args).length > 0) {
      query = '?' + (Object.getOwnPropertyNames(args).filter(key => args[key] !== null).map(key => `${key}=${encodeURIComponentOwn(args[key])}`).join('&'))
    }
    let headers = { }
    applyCommonHeaders(headers)
    return send(BASE_URL+path+query, { method: "get", headers: headers, })
  }

  const put = (path, id, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    applyCommonHeaders(headers)
    return send(BASE_URL+path+`/${id}`, { method: "put", body: JSON.stringify(data), headers: headers, })
  }

  const post = (path, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    applyCommonHeaders(headers)
    return send(BASE_URL+path, { method: "post", body: JSON.stringify(data), headers: headers, })
  }

  const postFormData = (path, formData) => {
    let headers = {}
    applyCommonHeaders(headers)
    return send(BASE_URL+path, { method: "post", body: formData, headers: headers, })
  }

  const _delete = (path, data) => {
    let headers = {}
    applyCommonHeaders(headers)
    return send(BASE_URL+path, { method: "delete", headers: headers, })
  }

  const applyCommonHeaders = (headers) => {
    if (!!user || !!tmpUser) {
      headers['X-Coloreal-AccessToken'] = user?.accessToken ?? tmpUser?.accessToken
    }
    if (!!currentApp) {
      headers['X-App-Token'] = currentApp.token
    }
  }

  return {
    user: {
      validateHash: (token) => get('/user/validateHash', { token: token }),
      login: (email, password) => post('/user/login', { email: email, password: password }),
      logout: () => _delete('/user/logout'),
      detail: (id) => get(`/user`, { id: id }),
      save: (data) => post(`/user`, data),
      list: (query, page, count, fields, orderBy) => get('/user/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      delete: (id) => _delete(`/user?id=${id}`),
    },
    app: {
      list: () => get('/app/list', {}),
      detail: (id) => get(`/app`, { id: id }),
      save: (data) => post(`/app`, data),
    },
    news: {
      list: (query, page, count, fields, orderBy) => get('/news/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      detail: (id, fields) => get(`/news`, { id: id, fields: fields }),
      save: (data) => post(`/news`, data),
    },
    region: {
      list: (query = null, page = null, count = null, fields = null, orderBy = null) => get('/region/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      detail: (id) => get(`/region`, { id: id }),
      save: (data) => post(`/region`, data),
      delete: (id) => _delete(`/region?id=${id}`),
    },
    lang: {
      list: (query, page, count, fields, orderBy) => get('/lang/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      detail: (id) => get(`/lang`, { id: id }),
      save: (data) => post(`/lang`, data),
      delete: (id) => _delete(`/lang?id=${id}`),
    },
    media: {
      upload: (formData) => postFormData('/media/upload', formData),
      delete: (id) => get(`/media/delete/${id}`),
    },
    selector: {
      detail: (id, fields) => get(`/selector`, { id: id, fields: fields}),
      save: (data) => post(`/selector`, data),
      list: (appId, query, page = null, count = null, fields = null, order = null) => get('/selector/list', { appId: appId, query: query, page: page, count: count, fields: fields, orderBy: order }),
      delete: (id) => _delete(`/selector?id=${id}`),
      copy: (originSelectorId, originFoilIds, selectorName, destinationAppId, destinationSelectorId) => post('/selector/copy', {
        originSelectorId: originSelectorId,
        originFoilIds: originFoilIds,
        selectorName: selectorName,
        destinationAppId: destinationAppId,
        destinationSelectorId: destinationSelectorId,
      })
    },
    brand: {
      detail: (id) => get(`/brand`, { id: id }),
      save: (data) => post(`/brand`, data),
      list: (query, page, count, fields, orderBy) => get('/brand/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      delete: (id) => _delete(`/brand?id=${id}`),
    },
    carBrand: {
      detail: (id) => get(`/carBrand`, { id: id }),
      save: (data) => post(`/carBrand`, data),
      list: (query = null, page = null, count = null, fields = null, orderBy = null) => get('/carBrand/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      delete: (id) => _delete(`/carBrand?id=${id}`),
    },
    car: {
      list: (query, page, count, fields, orderBy) => get('/car/list', { query: query, page: page, count: count, fields: fields, orderBy: orderBy }),
      detail: (id, fields) => get(`/car`, { id: id, fields: fields }),
      save: (data) => post(`/car`, data),
      delete: (id) => _delete(`/car?id=${id}`),
    },
    foil: {
      detail: (id, fields) => get(`/foil`, { id: id, fields: fields }),
      save: (selectorId, data) => post(`/foil`, { selectorId: selectorId, ...data }),
      list: (selectorId, query, page = null, count = null, fields = null, order = null) => get('/foil/list', { selectorId: selectorId, query: query, page: page, count: count, fields: fields, orderBy: order }),
      delete: (id) => _delete(`/foil?id=${id}`),
    },
    feedbacks: {
      detail: (id, fields) => get(`/feedback`, { id: id, fields: fields }),
      markAsSeen: (id, seen) => post(`/feedback/markAsSeen`, { id: id, seen: seen }),
      list: (query, page = null, count = null, fields = null, order = null) => get('/feedback/list', { query: query, page: page, count: count, fields: fields, orderBy: order }),
    },
    notification: {
      send: (title, content, regionIds) => post(`/notification/send`, { title: title, content: content, regionIds: regionIds }),
    },
  }
}