import { DateTime } from 'luxon'

export const USER_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const INPUT_DATE_TIME_FORMAT = 'yyyy-MM-dd\'T\'HH:mm'
export const SQL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'


export const parseUTC = (str) => {
  return DateTime.fromFormat(str, SQL_DATE_TIME_FORMAT, { zone: 'utc' }).toLocal()
}

export const dateToUserString = (str) => {
  return DateTime.fromFormat(str, SQL_DATE_TIME_FORMAT, { zone: 'utc' }).toLocal().toFormat(USER_DATE_TIME_FORMAT)
}

export const dateToInputString = (str) => {
  return DateTime.fromFormat(str, SQL_DATE_TIME_FORMAT, { zone: 'utc' }).toLocal().toFormat(INPUT_DATE_TIME_FORMAT)
}

export const dateFromInputStringToSQL = (str) => {
  return DateTime.fromFormat(str, INPUT_DATE_TIME_FORMAT).toUTC().toFormat(SQL_DATE_TIME_FORMAT)
}