import Iconify         from './Iconify'
import { t }                            from 'i18next'
import * as React                       from 'react'
import { Input }                        from '@mui/material'
import { read, utils }                  from 'xlsx';
import useLangs                         from '../hooks/useLangs'
import useRegions                       from '../hooks/useRegions'
import useBrands                        from '../hooks/useBrands'
import { useSnackbar }                  from 'notistack'
import { useApi }                       from '../utils/api'
import { useContext, useRef, useState } from 'react'
import { LoadingButton }                from '@mui/lab'
import { AppContext }                   from '../App'
import { USER_ROLE_APP_EDITOR }         from '../utils/consts'

String.prototype.lcFirst = function() {
  return this.charAt(0).toLowerCase() + this.slice(1);
}

const ifProvidedPropertyCodes = (source, property, originalObject, codes) => {
  let result = originalObject.hasOwnProperty(property) ? {...originalObject[property]} : {}
  codes.forEach(code => {
    let prop = `${code}_${property}`
    if (source.hasOwnProperty(prop)) {
      result[code] = source[prop]
    }
  })
  return result
}

const ifProvidedPropertyArray = (source, property, originalObject, defaultValue, suffixes, convertFunc) => {
  let hasAllParts = suffixes.every(suffix => source.hasOwnProperty(`${property}_${suffix}`))
  if (!hasAllParts) {
    return originalObject[property] ?? defaultValue
  }
  return suffixes.map(suffix => convertFunc(source[`${property}_${suffix}`]))
}

const ifProvidedProperty = (source, property, defaultObject, defaultValue = null) => {
  return source.hasOwnProperty(property) ? source[property] : (defaultObject[property] ?? defaultValue)
}

const ifProvidedPropertyCallback = (source, property, defaultObject, defaultValue, callback) => {
  return source.hasOwnProperty(property) ? callback(source[property]) : (defaultObject[property] ?? defaultValue)
}

class ParseError extends Error {
  constructor(message, objectType, objectName, exception) {
    super(message)
    this.type = objectType
    this.name = objectName
    this.exception = exception
  }
}

export default function ImportSelector({ selectorId }) {

  const api = useApi()
  const inputRef = useRef(null)
  const { user } = useContext(AppContext)
  const [langs] = useLangs()
  const [regions] = useRegions()
  const [brands] = useBrands()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const parseSelector = async (rawSelector) => {
    let regionCodes = regions.map(it => it.code)
    let regionIdMap = {}
    regions.forEach((r) => {
      regionIdMap[r.code] = r.id
    })
    let langCodes = langs.map(it => it.code)
    let brand = brands.find(brand => brand.name.toLowerCase() === rawSelector.brand.toLowerCase())
    if (!brand) {
      enqueueSnackbar(t('Imported Brand "{{brand}}" does not exists', { brand: rawSelector.brand }), {
        variant: 'error'
      })
      throw new Error("Imported brand does not exists")
    }
    let selector = {}
    if (!!rawSelector.id || selectorId) {
      // load selector for update from api
      const loadSelectorId = selectorId ?? rawSelector.id
      try {
        selector = await api.selector.detail(loadSelectorId, ['selectorDatasheets'])
      } catch (e) {
        throw new ParseError('Imported Selector does not exists', 'selector', `${loadSelectorId}`)
      }
    }
    delete selector.foils
    // keep the selectorDatasheets as it is
    selector.selectorDatasheets = selector.selectorDatasheets?.map(object => ({ lang: object.lang, mediaId: object.mediaId }))
    selector.brandId = brand.id
    selector.name = ifProvidedProperty(rawSelector, 'name', selector, '')
    selector.state = ifProvidedPropertyCallback(rawSelector, 'state', selector, 'draft', (state) => state.toLowerCase())
    selector.defaultLanguage = ifProvidedProperty(rawSelector, 'defaultLanguage', selector, 'EN')

    selector.regionIds = ifProvidedPropertyCallback(rawSelector, 'regions', selector, '',  (regions) => regions.split(',').map(code => regionIdMap[code]))

    selector.description = ifProvidedPropertyCodes(rawSelector, 'description', selector, langCodes)
    selector.descriptionTechnical = ifProvidedPropertyCodes(rawSelector, 'descriptionTechnical', selector, langCodes)
    selector.overview = ifProvidedPropertyCodes(rawSelector, 'overview', selector, langCodes)
    selector.howToUse = ifProvidedPropertyCodes(rawSelector, 'howToUse', selector, langCodes)

    return selector
  }

  const parseFoil = async (rawFoil, defaultSelectorId) => {
    let regionCodes = regions.map(it => it.code)
    let langCodes = langs.map(it => it.code)
    let foil = {}
    if (!!rawFoil.id) {
      // load foil for update from api
      try {
        foil = await api.foil.detail(rawFoil.id)
        // When component updating only one selector use the correct id (to allow import foils into other selector as it is)
        if (defaultSelectorId && foil.selectorId !== defaultSelectorId) {
          // & create new foil into defaultSelectorId
          foil = {
            selectorId: defaultSelectorId
          }
        }
      } catch (e) {
        throw new ParseError('Imported Foil does not exists', 'foil', `${rawFoil.id}`, e)
      }
    } else {
      foil = {
        // ...FoilDefaultValues,
        selectorId: defaultSelectorId,
      }
    }
    if (!foil.selectorId) {
      throw new ParseError('SelectorId for foil not found', 'foil', `${rawFoil.id}`)
    }

    foil.defaultLanguage = ifProvidedProperty(rawFoil, 'defaultLanguage', foil, null)
    foil.position = ifProvidedProperty(rawFoil, 'position', foil, null)
    foil.state = ifProvidedPropertyCallback(rawFoil, 'state', foil, 'draft', (state) => state.toLowerCase())
    foil.material = ifProvidedProperty(rawFoil, 'material', foil, 'basic_color')
    foil.durability = ifProvidedProperty(rawFoil, 'durability', foil, null)
    foil.adhesive = ifProvidedProperty(rawFoil, 'adhesive', foil, 'permanent')
    foil.transparency = ifProvidedProperty(rawFoil, 'transparency', foil, 'opaque')
    foil.colorCode = ifProvidedProperty(rawFoil, 'colorCode', foil, null)
    foil.ralCode = ifProvidedProperty(rawFoil, 'ralCode', foil, null)
    foil.calibratable = ifProvidedProperty(rawFoil, 'calibratable', foil, null)
    foil.pantone = ifProvidedProperty(rawFoil, 'pantone', foil, null)

    foil.search = ifProvidedPropertyCallback(rawFoil, 'search', foil, [], (search) => (search ?? '').split(','))
    foil.placeOfUse = ifProvidedPropertyCallback(rawFoil, 'placeOfUse', foil, [], (placeOfUse) => (placeOfUse ?? '').split(','))
    foil.finishing = ifProvidedPropertyCallback(rawFoil, 'finishing', foil, [], (finishing) => (finishing ?? '').split(','))
    foil.environment = ifProvidedPropertyCallback(rawFoil, 'environment', foil, [], (environment) => (environment ?? '').split(','))

    foil.name = ifProvidedPropertyCodes(rawFoil, 'name', foil, langCodes)
    foil.selectorSubcategory = ifProvidedPropertyCodes(rawFoil, 'selectorSubcategory', foil, langCodes)
    foil.info = ifProvidedPropertyCodes(rawFoil, 'info', foil, langCodes)

    foil.shopUrl = ifProvidedPropertyCodes(rawFoil, 'shopUrl', foil, ['default', ...regionCodes])

    if (user?.role !== USER_ROLE_APP_EDITOR) {
      foil.specularExp = ifProvidedProperty(rawFoil, 'specularExp', foil, null)
      foil.metalizedStrength = ifProvidedProperty(rawFoil, 'metalizedStrength', foil, null)
      foil.metalizedDistortion = ifProvidedProperty(rawFoil, 'metalizedDistortion', foil, null)
      foil.lab = ifProvidedPropertyArray(rawFoil, 'lab', foil, [null, null, null], ['L', 'A', 'B'], parseFloat)
      foil.diffuseRgb = ifProvidedPropertyArray(rawFoil, 'diffuseRgb', foil, [null, null, null], ['R', 'G', 'B'], parseInt)
      foil.specularRgb = ifProvidedPropertyArray(rawFoil, 'specularRgb', foil, [null, null, null], ['R', 'G', 'B'], parseInt)
      foil.glitterDiffuseRgb = ifProvidedPropertyArray(rawFoil, 'glitterDiffuseRgb', foil, [null, null, null], ['R', 'G', 'B'], parseInt)
    }
    return foil
  }

  const processSelectorSheet = async (sheet) => {
    const rawSelectors = utils.sheet_to_json(sheet, { defval: null });
    let updatedSelectors = []
    let parsedSelectors = []
    try {
      parsedSelectors = await Promise.all(rawSelectors.map((rawSelector) => parseSelector(rawSelector)))
      for(const selectorToSave of parsedSelectors) {
        let selector = await api.selector.save(selectorToSave)
        updatedSelectors.push(selector.id)
      }
    } catch (error) {
      console.error(error)
      if (error instanceof ParseError) {
        let message = t('{{object}} #{{name}} does not exists', { object: error.type.lcFirst(), name: error.name })
        enqueueSnackbar(message, {
          variant: 'error'
        })
      } else if (error.error || error.statusText) {
        enqueueSnackbar(t('Failed to save selector {{error}}', { error: error.error ?? error.statusText ?? error }), {
          variant: 'error'
        })
      }
    }

    if (parsedSelectors.length > 0) {
      enqueueSnackbar(t(`{{count}} of {{totalCount}} selectors saved`, { count: updatedSelectors.length, totalCount: parsedSelectors.length }), {
        variant: updatedSelectors.length < parsedSelectors.length ? 'warning' : 'success'
      })
    }
    return updatedSelectors
  }

  const processFoilsSheet = async (sheet, firstSelectorId) => {
    let updatedFoils = []
    let parsedFoils = []
    const rawFoils = utils.sheet_to_json(sheet, { defval: null });
    try {
      parsedFoils = await Promise.all(rawFoils.map((rawFoil) => parseFoil(rawFoil, firstSelectorId)))
      for(const foilToSave of parsedFoils) {
        let foil = await api.foil.save(foilToSave.selectorId, foilToSave)
        updatedFoils.push(foil.id)
      }
    } catch (error) {
      if (error instanceof ParseError) {
        let message = t('{{object}} #{{name}} does not exists', { object: error.type.lcFirst(), name: error.name })
        enqueueSnackbar(message, {
          variant: 'error'
        })
      } else if (error.error || error.statusText) {
        enqueueSnackbar(t('Failed to save foil {{error}}', { error: error.error ?? error.statusText ?? error }), {
          variant: 'error'
        })
      }
    }
    if (parsedFoils.length > 0) {
      enqueueSnackbar(t(`{{count}} of {{totalCount}} foils saved`, { count: updatedFoils.length, totalCount: parsedFoils.length }), {
        variant: 'success'
      })
    }
  }

  const onFilesChanged = async (event) => {
    if (event.target.files.length !== 1) {
      return
    }
    setLoading(true)
    let updatedSelectorIds = []
    // do the excel stuff
    const workBook = read(await event.target.files[0].arrayBuffer());
    if (workBook.Sheets.hasOwnProperty('Selector')) {
      updatedSelectorIds = await processSelectorSheet(workBook.Sheets['Selector'])
    }
    // parse foils
    if (workBook.Sheets.hasOwnProperty('Foils')) {
      let selectorId = updatedSelectorIds.length > 0 ? updatedSelectorIds[0] : null
      await processFoilsSheet(workBook.Sheets['Foils'], selectorId)
    }
    inputRef.current.value = null
    setLoading(false)
  }

  return (
    <label>
      <Input inputRef={inputRef}
             accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
             type="file"
             sx={{ display: 'none' }}
             onChange={onFilesChanged} />
      <LoadingButton
        loading={loading}
        loadingPosition={'start'}
        variant={'text'}
        color={'primary'}
        component="span"
        startIcon={<Iconify icon="eva:upload-fill" />}>
        {t('Import selector & foils')}
      </LoadingButton>
    </label>
  )
}