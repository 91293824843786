import { useApi }                                    from '../utils/api'
import { useCallback, useEffect, useState } from 'react'

let langs
export default function useLangs() {
  const api = useApi()
  const [local, setLocal] = useState(langs)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    langs = undefined
    setReload(reload => reload + 1)
  }, [])

  const defaultLang = local?.filter(l => l.isDefault).pop()

  useEffect(() => {
    async function load() {
      langs = (await api.lang.list()).data
      setLocal(langs)
    }
    if (langs === undefined) {
      load()
    }
  }, [reload])

  return [local, defaultLang, reloadFnc]
}