import {
  Box, Container,
  Grid, Skeleton,
  Stack
}            from '@mui/material'
import React from 'react'

export default function FoilLoaderSkeleton() {

  return (
    <Container>
      <Box>
        <Stack direction="row" spacing={1} justifyContent={'end'} flexShrink={0} sx={{ my: 1 }}>
          <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
        </Stack>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={2}>
          <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={38} />
            <Skeleton variant="rectangular" height={38} />
            <Skeleton variant="rectangular" height={38} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={38} />
            <Skeleton variant="rectangular" height={38} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Skeleton variant="text" height={38} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" height={38} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2}>
            <Skeleton variant="rectangular" width={'20%'} height={100} />
            <Skeleton variant="rectangular" width={'20%'} height={100} />
            <Skeleton variant="rectangular" width={'20%'} height={100} />
            <Skeleton variant="rectangular" width={'20%'} height={100} />
            <Skeleton variant="rectangular" width={'20%'} height={100} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}