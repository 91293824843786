import { useApi }                                    from '../utils/api'
import { useCallback, useEffect, useState } from 'react'

let carBrands
export default function useCarBrands() {
  const api = useApi()
  const [local, setLocal] = useState(carBrands)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    carBrands = undefined
    setReload(reload => reload + 1)
  }, [])

  useEffect(() => {
    async function load() {
      carBrands = (await api.carBrand.list()).data
      setLocal(carBrands)
    }
    if (carBrands === undefined) {
      load()
    }
  }, [reload])

  return [local, reloadFnc]
}