import Page                                             from '../components/Page'
import { Avatar, Button, Chip, Container, Link, Stack } from '@mui/material'
import Typography                                       from '@mui/material/Typography'
import { Link as RouterLink }       from 'react-router-dom'
import Iconify                      from '../components/Iconify'
import BaseTable                    from '../components/base/BaseTable'
import React                        from 'react'
import { t }                        from 'i18next'
import { useApi }                   from '../utils/api'

export default function Brands() {

  const api = useApi();
  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, filterable: false },
    { field: 'name', headerName: t('Name'), flex: 2, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            size="small"
            to={`/dashboard/brands/${cell.id}`}
          >
            {cell.row.name}
          </Link>
        )
      }, },
    { field: 'active', headerName: t('Active'), flex: 2, filterable: false, renderCell: (cell) => {
        return (
          <Chip
            size={'small'}
            label={t(cell.row.active ? 'Active' : 'Inactive')}
            variant={cell.row.active ? 'outlined' : 'filled'}
            color={cell.row.active ? 'primary' : 'default'} />
        )
      }, },
  ];

  return (<Page title={t('Brands')}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>{t('Brands')}</Typography>
        <Button variant="contained" component={RouterLink} to="/dashboard/brands/create" startIcon={<Iconify icon="eva:plus-fill" />}>
          {t('New brand')}
        </Button>
      </Stack>

      <BaseTable
        columns={columns}
        loadCallback={async (query, page, pageSize, orderBy) => {
          try {
            return await api.brand.list(query, page, pageSize, [], orderBy)
          } catch (e) {
            console.log('Failed to load users', e)
            return []
          }
        }}
      />
    </Container>
  </Page>);

}