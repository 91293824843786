import { Dialog, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import PropTypes                                                    from 'prop-types'
import * as React                                                   from 'react'
import Slide                                                        from '@mui/material/Slide'
import { useTranslation }                                           from 'react-i18next'
import DialogContent                                                from '@mui/material/DialogContent'
import DialogContentText                                            from '@mui/material/DialogContentText'
import DialogActions                                                from '@mui/material/DialogActions'
import Button                                                       from '@mui/material/Button'
import { t }                                                        from 'i18next'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function AlertSimpleDialog (props) {

  const { t } = useTranslation()
  const { onClose, open, title, message, ...other } = props

  const handleCancel = () => onClose(false)
  const handleOk = () => onClose(true)

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      aria-describedby="alert-dialog-slide-description"
      sx={{ zIndex: 1400 }}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('Cancel')}</Button>
        <Button onClick={handleOk}>{t('OK')}</Button>
      </DialogActions>
    </Dialog>
  )
}

AlertSimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

function ConfirmationDialogRaw (props) {
  const { onClose, value: valueProp, open, title, options, ...other } = props
  const [value, setValue] = React.useState(valueProp)
  const radioGroupRef = React.useRef(null)

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp)
    }
  }, [valueProp, open])

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    onClose(value)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                value={option.value}
                key={option.key}
                control={<Radio/>}
                label={option.label}
              />
            )
          })}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>{t('Cancel')}</Button>
        <Button onClick={handleOk}>{t('OK')}</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}