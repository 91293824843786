import React                                                 from 'react'
import { Avatar, Box, Button, Chip, Container, Link, Stack } from '@mui/material'
import BaseTable                                             from '../components/base/BaseTable'
import { useApi }                               from '../utils/api'
import { Link as RouterLink }                   from 'react-router-dom'
import { useTranslation }                       from 'react-i18next'
import { parseUTC, USER_DATE_TIME_FORMAT }      from '../utils/dateutils'
import Typography                               from '@mui/material/Typography'
import Iconify                                  from '../components/Iconify'
import Page                                     from '../components/Page'

export default function News () {

  const api = useApi()
  const { t } = useTranslation()

  const columns = [
    { field: 'id', headerName: t('ID'), width: 70, filterable: false },
    {
      field: 'title', headerName: t('Title'), flex: 1, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            size="small"
            to={`/dashboard/news/${cell.row.id}`}
          >
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              {cell.row.thumbnail?.url && <Avatar alt={cell.row.title}
                      src={cell.row.thumbnail.url}
                      sx={{ width: 32, height: 32 }}
              />}
              <Typography sx={{ fontSize: 'sm', fontWeight: 'md' }}>
                {cell.row.title}
              </Typography>
            </Stack>
          </Link>
        )
      },
    },
    { field: 'content', headerName: t('Content'), flex: 2, filterable: false },
    {
      field: 'published_from',
      headerName: t('Published'),
      width: 260,
      filterable: false,
      renderCell: (cell) => {
        let pubFrom = !!cell.row.publishedFrom ? parseUTC(cell.row.publishedFrom) : null
        let pubTo = !!cell.row.publishedTo ? parseUTC(cell.row.publishedTo) : null
        let isPublished = pubFrom != null && pubFrom.diffNow() < 0 && (pubTo == null || pubTo.diffNow() > 0)
        return (
          <Chip color={isPublished ? 'success' : 'error'}
                variant={'outlined'}
                size={'small'}
                label={`${pubFrom?.toFormat(USER_DATE_TIME_FORMAT) ?? ''} - ${pubTo?.toFormat(USER_DATE_TIME_FORMAT) ?? t('forever')}`}/>
        )
      },
    },
  ]

  return (<>
    <Page title={t('News')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>{t('News')}</Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/news/create"
                  startIcon={<Iconify icon="eva:plus-fill"/>}>
            {t('Create news')}
          </Button>
        </Stack>
        <BaseTable
          columns={columns}
          rowHeight={48}
          loadCallback={async (query, page, pageSize, orderBy) => {
            try {
              return await api.news.list(query ? query : null, page, pageSize, ['thumbnail'], orderBy)
            } catch (e) {
              console.log('Failed to load news', e)
              return []
            }
          }}
        />
      </Container>
    </Page>
  </>)

}