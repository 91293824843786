import PropTypes  from 'prop-types'
import useRegions from '../../hooks/useRegions'
import {
  Box,
  Stack,
  TextField
}                 from '@mui/material'
import Iconify             from '../../components/Iconify'
import React, { useState } from 'react'

const DEFAULT_REGION = {
  id: 0,
  code: 'default',
  name: 'Default',
  icon: 'eva:flag-outline',
}

export default function FoilShopUrl (props) {
  const { values, onValuesChanged } = props
  const [_values, setValues] = useState(values)
  const [regions] = useRegions()

  const handleChange = (code) => (e) => {
    setValues((prevValues) => {
      let val = {
        ...prevValues,
      }
      val[code] = e.target.value
      return val
    })
  }

  return (<>
    <Stack direction={'column'} spacing={1}>
        {[DEFAULT_REGION].concat(regions ?? []).map(region => {
          return (
            <Box key={`region-${region.id}`} sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Iconify icon={region.icon ?? `flagpack:${region.code}`} sx={{ color: 'action.active', mr: 1, my: 1 }} />
              <TextField
                fullWidth
                label={region.name}
                variant="standard"
                value={_values[region.code] ?? ''}
                onChange={handleChange(region.code)}
                onBlur={() => {
                  onValuesChanged(_values)
                }}
              />
            </Box>
          )
        })}
    </Stack>
    </>)
}

FoilShopUrl.propTypes = {
  values: PropTypes.object.isRequired,
  onValuesChanged: PropTypes.func.isRequired,
}