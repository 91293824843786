import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar, LinearProgress,
  ListSubheader, Stack,
}                                     from '@mui/material'
import FileUploadIcon
                                      from '@mui/icons-material/FileUpload'
import { styled }                     from '@mui/material/styles'
import IconButton                     from '@mui/material/IconButton'
import DeleteIcon                     from '@mui/icons-material/Delete'
import React, { useEffect, useState } from 'react'
import PropTypes                      from 'prop-types'
import { useApi }                     from '../utils/api'
import { useSnackbar }                from 'notistack'
import { t }                          from 'i18next'
import Typography                     from '@mui/material/Typography'
import Iconify                        from './Iconify'

const Input = styled('input')({
  display: 'none',
})

export default function DocumentFileUpload (props) {

  const { title, accept, multiple, data, onDataChanged } = props

  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const onFilesChanged = (event) => {
    if (event.target.files.length === 0) {
      return
    }
    const formData = new FormData()
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append(`file-${i}`, event.target.files[i], event.target.files[i].name)
    }

    api.media.upload(formData).then(response => {
      onDataChanged([...data, ...response.data])
    }).catch(error => {
      enqueueSnackbar(t(`Failed to upload file ${error.error}`), {
        variant: 'error'
      })
    })
  }

  const handleFileDelete = (media) => (event) => {
      onDataChanged(data.filter(it => it.id !== media.id))
  }

  return (
    <Box>
      {title && <Typography variant={'subtitle2'} color={'text.secondary'}>{title}</Typography>}
      <Stack direction={'column'}>
        {data.map(media => (
          <Stack key={`media-${media.id}`} direction={'row'} spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <IconButton onClick={handleFileDelete(media)} sx={{ flex: '0 0 50px' }}>
                <Iconify icon={'eva:trash-2-outline'} />
              </IconButton>
            </Box>
            <Box>
              <IconButton href={media.url} target={'_blank'} color={'primary'} sx={{ flex: '0 0 50px' }}>
                <Iconify icon={'eva:file-text-outline'} />
              </IconButton>
            </Box>
            <Typography variant={'caption'} sx={{ flex: 1 }}>{media.nameBase}</Typography>
          </Stack>
        ))}
      </Stack>
      {(data.length === 0 || multiple) && (<label>
        <Input accept={accept ?? '*'}
               type="file"
               multiple={multiple ?? false}
               onChange={onFilesChanged} />
        <Button variant={'text'} fullWidth component="span">
          <Iconify icon={'eva:cloud-upload-outline'} sx={{ height: 32, width: 32 }} />
        </Button>
      </label>)}
    </Box>
  )
}

DocumentFileUpload.propTypes = {
  title: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  data: PropTypes.array.isRequired,
  onDataChanged: PropTypes.func.isRequired,
}