import Page                                                                                 from '../components/Page'
import { t }                                                                                       from 'i18next'
import {
  FormControl, FormControlLabel,
  Grid,
  Input,
  InputLabel, Switch,
} from '@mui/material'
import { useNavigate, useParams }     from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../utils/api'
import { useSnackbar }       from 'notistack'
import BaseForm              from './BaseForm'

export default function Lang () {

  const { langId } = useParams()
  const api = useApi()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [values, setValues] = useState({
    name: '',
    code: '',
    isDefault: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  useEffect(() => {
    (async () => {
      if (!isNaN(langId)) {
        let lang = await api.lang.detail(langId)
        setValues({
          name: '',
          code: '',
          isDefault: false,
          ...lang
        })
      }
    })()
  }, [langId])

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    try {
      let lang = await api.lang.save(data)
      navigate(`/dashboard/langs/${lang.id}`)
      enqueueSnackbar(t('Lang saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save lang'), {
        variant: 'error'
      })
    }
  }

  const onDelete = (id) => {
    api.lang.delete(langId).then(() => {
      enqueueSnackbar(t('Lang deleted'), {
        variant: 'success'
      })
      navigate('/dashboard/langs')
    }).catch(error => {
      enqueueSnackbar(t('Failed to delete lang'), {
        variant: 'error'
      })
    })
  }

  return (<Page title={t('Lang')}>
    <BaseForm
      id={isNaN(langId) ? 0 : parseInt(langId)}
      formTitle={t('Lang')}
      values={values}
      onSubmit={onSubmit}
      onDelete={onDelete}
    >
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="lang-name">{t('Name')}</InputLabel>
          <Input
            id="lang-name"
            value={values.name}
            onChange={handleChange('name')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="lang-code">{t('Code')}</InputLabel>
          <Input
            id="lang-code"
            value={values.code}
            onChange={handleChange('code')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch
            checked={values.isDefault}
            onChange={(e) => handleChange('isDefault')({ target: { value: !values.isDefault } })}
          />}
          label={t('Default')}/>
      </Grid>
    </BaseForm>
  </Page>)
}