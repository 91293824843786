import { t }                          from 'i18next'
import {
  Box,
  Button, Container,
  FormControl, FormControlLabel,
  FormLabel,
  Grid, InputAdornment,
  InputLabel,
  MenuItem, Radio, RadioGroup,
  Select,
  Stack, Switch,
  TextField
}                                     from '@mui/material'
import Typography                     from '@mui/material/Typography'
import Iconify                        from '../components/Iconify'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../utils/api'
import BaseLangSelect                 from '../components/base/BaseLangSelect'
import {
  TagsAutocomplete,
  TextCheckboxes
}                                     from '../components/Checkboxes'
import FoilShopUrl                    from '../sections/foil/FoilShopUrl'
import ColorInput                     from '../sections/foil/ColorInput'
import Loader                         from '../components/Loader'
import FoilTextures                   from '../sections/foil/FoilTextures'
import {
  MATERIAL_TEXTURES,
  MATERIALS,
  ADHESIVES,
  TRANSPARENCY,
  PLACE_OF_USE,
  FINISHING,
  ENVIRONMENT
}                                     from '../utils/consts'
import PropTypes                      from 'prop-types'
import FoilLoaderSkeleton             from '../sections/foil/FoilLoaderSkeleton'

const defaultValues = {
  version: 1,
  name: {},
  selectorSubcategory: {},
  info: {},
  colorCode: '',
  ralCode: '',
  position: 0,
  durability: '',
  calibratable: false,
  search: [],
  state: 'draft',
  material: 'basic_color',
  adhesive: '',
  transparency: '',
  placeOfUse: [],
  finishing: [],
  environment: [],
  shopUrl: {},
  lab: [null, null, null],
  diffuseRgb: [null, null, null],
  specularRgb: [null, null, null],
  glitterDiffuseRgb: [null, null, null],
  specularExp: null,
  metalizedStrength: null,
  metalizedDistortion: null,
}

const Foil = React.forwardRef((props, ref) => {

  const api = useApi()
  const { selectorId, foilId, showColorParams, onFoilLoaded } = props
  const [values, setValues] = useState(defaultValues)
  const [lang, setLang] = useState()
  const [shopUrlOpen, setShopUrlOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [textureMedia, setTextureMedia] = useState([])

  React.useImperativeHandle(ref, () => ({
    getFormValues: () => {
      let requestValues = { ...values }
      requestValues.selectorId = selectorId
      requestValues.textures = textureMedia
        ?.map(tMedia => ({ type: tMedia.type, mediaId: tMedia.media.id }))
        ?.filter(tMedia => MATERIAL_TEXTURES[values.material]?.indexOf(tMedia.type) > -1) ?? []
      return requestValues
    }
  }))

  useEffect(() => {
    (async () => {
      if (!isNaN(foilId) || foilId === null) {
        setLoading(true)
        let foil = foilId === null ? {} : await api.foil.detail(foilId, ['textures'])
        setValues(() => ({
          ...defaultValues,
          ...foil,
        }))
        onFoilLoaded(foil)
        setTextureMedia(foil?.textures ?? [])
      }
      setLoading(false)
    })();
  }, [foilId])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const handleTranslatedValue = (prop) => (event) => {
    let property = values[prop]
    property[lang] = event.target.value
    setValues({ ...values, [prop]: property });
  }

  const translatedValue = (property) => {
    return (values[property] ?? {})[lang] ?? ''
  }

  const onSubmit = async (e) => {
    e.preventDefault()
  }

  const formSelect = (property, title, options) => (
    <FormControl variant="standard" fullWidth>
      <InputLabel htmlFor={`foil-${property}`}>{title}</InputLabel>
      <Select
        id={`foil-${property}`}
        value={values[property]}
        onChange={handleChange(property)}
        label={title}
      >
        {options.map((it) => (
          <MenuItem key={it} value={it}>{it}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  return (
      <Loader loading={loading} component={<FoilLoaderSkeleton />}>
        <Container>
          <Box>
            <Stack direction="row" spacing={1} justifyContent={'end'} flexShrink={0} sx={{ my: 1 }}>
              <BaseLangSelect onLangChanged={(lang) => {
                setLang(lang?.code)
              }}/>
            </Stack>
          </Box>
          <Grid component={'form'} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('General')}</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField id="field-name" label={t('Selector subcategory')} fullWidth variant="standard" sx={{ mb: 1 }}
                           value={translatedValue('selectorSubcategory')} onChange={handleTranslatedValue('selectorSubcategory')} />
                <TextField id="field-name" label={t('Name')} fullWidth variant="standard" sx={{ mb: 1 }}
                           value={translatedValue('name')} onChange={handleTranslatedValue('name')} />
                <TextField id="field-info" label={t('Info')} fullWidth variant="standard" sx={{ mb: 1 }}
                           multiline maxRows={5}
                           value={translatedValue('info')} onChange={handleTranslatedValue('info')} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="field-color-code" label={t('Color code')} fullWidth variant="standard" sx={{ mb: 1 }}
                           value={values.colorCode} onChange={handleChange('colorCode')} />
                <TextField id="field-ral-code" label={t('Ral code')} fullWidth variant="standard" sx={{ mb: 1 }}
                           value={values.ralCode} onChange={handleChange('ralCode')} />
              </Grid>
              <Grid item xs={12}>
                <TagsAutocomplete values={values.search} onValuesChanged={(options) => {
                  handleChange('search') ( { target: { value: options } })
                }} />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl>
                  <FormLabel id="label-state">{t('State')}</FormLabel>
                  <RadioGroup row aria-labelledby="label-state" name="state" value={values.state} onChange={handleChange('state')}>
                    <FormControlLabel value="draft" control={<Radio />} label={t('Draft')} />
                    <FormControlLabel value="published" control={<Radio />} label={t('Published')} />
                    <FormControlLabel value="deleted" control={<Radio />} label={t('Deleted')} />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="field-ral-code" label={t('Position')} fullWidth variant="standard" type={'number'} sx={{ mb: 1 }}
                           value={values.position} onChange={handleChange('position')} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('Properties')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {formSelect('material', t('Material'), Object.values(MATERIALS))}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="field-durability"
                  label={t('Durability')}
                  type={'number'}
                  fullWidth
                  sx={{ mb: 1 }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{t('years')}</InputAdornment>,
                  }}
                  variant="standard"
                  value={values.durability ?? ''}
                  onChange={handleChange('durability')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={<Switch
                    checked={values.calibratable}
                    onChange={(e) => handleChange('calibratable')({ target: { value: !values.calibratable } })}
                  />}
                  label={t('Calibratable')}/>
              </Grid>
              <Grid item xs={12}>
                <TextCheckboxes id={'placeOfUse'} title={t('Place of use')}
                                values={values.placeOfUse} options={PLACE_OF_USE}
                                onValuesChanged={(newValues) => {
                                  handleChange('placeOfUse')({ target: { value: newValues } })
                                }} />
              </Grid>
              <Grid item xs={12}>
                <TextCheckboxes id={'finishing'} title={t('Finishing')}
                                values={values.finishing} options={FINISHING}
                                onValuesChanged={(newValues) => {
                                  handleChange('finishing')({ target: { value: newValues } })
                                }} />
              </Grid>
              <Grid item xs={12}>
                <TextCheckboxes id={'environment'} title={t('Environment')}
                                values={values.environment} options={ENVIRONMENT}
                                onValuesChanged={(newValues) => {
                                  handleChange('environment')({ target: { value: newValues } })
                                }} />
              </Grid>
              <Grid item xs={12} md={6}>
                {formSelect('adhesive', t('Adhesive'), ADHESIVES)}
              </Grid>
              <Grid item xs={12} md={6}>
                {formSelect('transparency', t('Transparency'), TRANSPARENCY)}
              </Grid>
              <Grid item xs={12} mt={2}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="subtitle1">{t('Shop URL')}</Typography>
                  <Button
                    variant={'text'}
                    color={'inherit'}
                    disableRipple
                    endIcon={<Iconify icon={shopUrlOpen ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
                    onClick={(e) => {
                      setShopUrlOpen((prevValue) => !prevValue)
                    }}
                  >
                    {shopUrlOpen ? t('Hide') : t('Show')}
                  </Button>
                </Stack>
              </Grid>
              {shopUrlOpen && <Grid item xs={12}>
                <FoilShopUrl values={values.shopUrl} onValuesChanged={(urls) => {
                  handleChange('shopUrl')({ target: { value: urls } })
                }} />
              </Grid>}
              {showColorParams && <>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{t('Special scene and material parameters')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <ColorInput title={'LAB Color'}
                              partTitles={['L', 'A', 'B']}
                              defaultValues={values.lab}
                              onChangeValues={(array) => handleChange('lab')( { target: { value: array } } )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <ColorInput title={'Diffuse color'}
                              partTitles={['R', 'G', 'B']}
                              defaultValues={values.diffuseRgb}
                              onChangeValues={(array) => handleChange('diffuseRgb')( { target: { value: array } } )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <ColorInput title={'Specular color'}
                              partTitles={['R', 'G', 'B']}
                              defaultValues={values.specularRgb}
                              onChangeValues={(array) => handleChange('specularRgb')( { target: { value: array } } )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <ColorInput title={'Glitter diffuse color'}
                              partTitles={['R', 'G', 'B']}
                              defaultValues={values.glitterDiffuseRgb}
                              onChangeValues={(array) => handleChange('glitterDiffuseRgb')( { target: { value: array } } )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField id="field-specularExp" label={t('Specular exponent')} fullWidth variant="standard"
                             type={'number'} sx={{ mb: 1 }}
                             inputProps={{
                               step: 0.001,
                             }}
                             value={values.specularExp ?? ''} onChange={handleChange('specularExp')} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField id="field-metalizedStrength" label={t('Metalized strength')} fullWidth variant="standard"
                             type={'number'} sx={{ mb: 1 }}
                             inputProps={{
                               step: 0.001,
                               min: 0,
                               max: 1,
                             }}
                             value={values.metalizedStrength ?? ''} onChange={handleChange('metalizedStrength')} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField id="field-metalizedDistortion" label={t('Metalized distortion')} fullWidth variant="standard"
                             type={'number'} sx={{ mb: 1 }}
                             inputProps={{
                               step: 0.001,
                               min: 0,
                             }}
                             value={values.metalizedDistortion ?? ''} onChange={handleChange('metalizedDistortion')} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{t('Textures')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FoilTextures material={values.material} defaultValues={textureMedia} onValuesChanged={(newValues) => {
                    setTextureMedia(newValues)
                  }} />
                </Grid>
              </>}
            </Grid>
          </Grid>
        </Container>
      </Loader>
  )
})

Foil.propTypes = {
  foilId: PropTypes.number,
  selectorId: PropTypes.number,
  showColorParams: PropTypes.bool.isRequired,
  onFoilLoaded: PropTypes.func.isRequired,
}

export default Foil