import * as React               from 'react'
import { useContext, useState } from 'react'
import PropTypes                from 'prop-types'
import Iconify                  from './Iconify'
import { t }                    from 'i18next'
import LoadingButton            from '@mui/lab/LoadingButton'
import { utils, writeFileXLSX } from 'xlsx'
import { useApi }               from '../utils/api'
import useLangs                 from '../hooks/useLangs'
import useRegions               from '../hooks/useRegions'
import { AppContext }           from '../App'
import { USER_ROLE_APP_EDITOR } from '../utils/consts'

const exportArrayProperty = (sourceArray, destination, property, propSuffixes) => {
  propSuffixes.forEach((suffix, index) => {
    destination[`${property}_${suffix}`] = sourceArray.length === propSuffixes.length ? sourceArray[index] : ''
  })
}

const exportLangMapProperties = (source, destination, properties, langs) => {
  langs.forEach((langObject) => {
    let lang = langObject['code']
    properties.forEach((propertyName) => {
      destination[`${lang}_${propertyName}`] = source[propertyName][lang] ?? ''
    })
  })
}

const exportRegionProperty = (source, destination, property, regions) => {
  regions.forEach(region => {
    let regionCode = region.code
    destination[`${regionCode}_${property}`] = source[property][regionCode] ?? ''
  })
}

export default function ExportSelectors (props) {

  const { selectorIds, isExportFoils } = props

  const api = useApi()
  const { currentApp, user } = useContext(AppContext)
  const [langs] = useLangs()
  const [regions] = useRegions()
  const [loading, setLoading] = useState(false)

  const downloadSelectors = async (selectorIds) => {
    let result = []
    for (const selectorId of selectorIds) {
      const selector = await api.selector.detail(selectorId, ['brand'])
      let exportedSelector = {
        id: selector.id,
        name: selector.name,
        brand: selector.brand.name,
        state: selector.state,
        defaultLanguage: selector.defaultLanguage,
        regions: (selector.regions ?? []).join(','),
      }
      exportLangMapProperties(selector, exportedSelector, ['description', 'descriptionTechnical', 'overview', 'howToUse'], langs)
      result.push(exportedSelector)
    }
    return result
  }

  const downloadFoils = async (selectorId) => {
    let { data } = await api.foil.list(selectorId, null, 0, 1000, ['textures'], ['position', 'ASC'])
    return data.map(foil => {
      let foilToExport = {
        id: foil.id,
        defaultLanguage: foil.defaultLanguage,
        position: foil.position,
        state: foil.state,
        material: foil.material,
        durability: foil.durability,
        adhesive: foil.adhesive,
        transparency: foil.transparency,
        colorCode: foil.colorCode,
        ralCode: foil.ralCode,
        calibratable: foil.calibratable,
        pantone: foil.pantone,

        search: (foil.search ?? []).join(','),
        placeOfUse: (foil.placeOfUse ?? []).join(','),
        finishing: (foil.finishing ?? []).join(','),
        environment: (foil.environment ?? []).join(','),
      }
      exportLangMapProperties(foil, foilToExport, ['name', 'selectorSubcategory', 'info'], langs)
      exportRegionProperty(foil, foilToExport, 'shopUrl', [{ 'code': 'default' }, ...regions])

      if (user?.role !== USER_ROLE_APP_EDITOR) {
        foilToExport.specularExp = foil.specularExp
        foilToExport.metalizedStrength = foil.metalizedStrength
        foilToExport.metalizedDistortion = foil.metalizedDistortion
        exportArrayProperty(foil.lab, foilToExport, 'lab', ['L', 'A', 'B'])
        exportArrayProperty(foil.diffuseRgb, foilToExport, 'diffuseRgb', ['R', 'G', 'B'])
        exportArrayProperty(foil.specularRgb, foilToExport, 'specularRgb', ['R', 'G', 'B'])
        exportArrayProperty(foil.glitterDiffuseRgb, foilToExport, 'glitterDiffuseRgb', ['R', 'G', 'B'])
      }
      return foilToExport
    })
  }

  /* get state data and export to XLSX */
  const exportFile = async () => {
    setLoading(true)
    const workBook = utils.book_new()
    // first download selectors & foils
    let selectorIdsValues = selectorIds()
    let exportedSelectors = await downloadSelectors(selectorIdsValues)
    const workSheetSelector = utils.json_to_sheet(exportedSelectors)
    utils.book_append_sheet(workBook, workSheetSelector, 'Selector')
    if (isExportFoils && selectorIdsValues.length === 1) {
      let exportedFoils = await downloadFoils(selectorIdsValues[0])
      const workSheetFoils = utils.json_to_sheet(exportedFoils)
      utils.book_append_sheet(workBook, workSheetFoils, 'Foils')
    }
    let selectorName = `${currentApp.name} ` + (isExportFoils && selectorIdsValues.length === 1 ? `selector-${selectorIdsValues[0]}` : 'selectors')
    writeFileXLSX(workBook, `${selectorName}.xlsx`)
    setLoading(false)
  }

  return (
    <LoadingButton
      variant={'text'}
      color={'primary'}
      loading={loading}
      onClick={exportFile}
      loadingPosition={'start'}
      startIcon={<Iconify icon="eva:download-fill"/>}>
      {isExportFoils ? t('Export selector & foils') : t('Export selectors')}
    </LoadingButton>
  )
}

ExportSelectors.propTypes = {
  selectorIds: PropTypes.func,
  isExportFoils: PropTypes.bool,
}