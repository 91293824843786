const keySelectorSearch = 'sk.coloreal.selectors.search'
const keyUser = 'sk.coloreal.user'
const keyApp = 'sk.coloreal.app'

export const setSelectorSearch = (object) => setItemImpl(keySelectorSearch, object)
export const getSelectorSearch = () => getItemImpl(keySelectorSearch, {})
export const getLocalStorageUser = () => getItemImpl(keyUser)
export const getLocalStorageApp = () => getItemImpl(keyApp)
export const setLocalStorageUser = (user) => setItemImpl(keyUser, user)
export const setLocalStorageApp = (app) => setItemImpl(keyApp, app)

const setItemImpl = (key, object) => object ? localStorage.setItem(key, JSON.stringify(object)) : localStorage.removeItem(key)
const getItemImpl = (key, defaultValue = null) => {
  let json = localStorage.getItem(key) ?? null
  return json === null ? defaultValue : JSON.parse(json)
}