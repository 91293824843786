import Page                                             from '../components/Page'
import { Avatar, Button, Chip, Container, Link, Stack } from '@mui/material'
import Typography                                       from '@mui/material/Typography'
import { Link as RouterLink }       from 'react-router-dom'
import Iconify                      from '../components/Iconify'
import BaseTable                    from '../components/base/BaseTable'
import React                        from 'react'
import { t }                        from 'i18next'
import { useApi }                   from '../utils/api'

export default function Feedbacks() {

  const api = useApi();
  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, filterable: false },
    { field: 'seen', headerName: t('Seen'), width: 80, filterable: false, renderCell: (cell) => {
      return (<Iconify
        sx={{
          color: cell.row.seen != null ? 'text.secondary' : 'error.main',
          width: 24,
          height: 24,
        }}
        icon={cell.row.seen != null ? 'eva:eye-fill' : 'eva:eye-off-fill'}
      />)
      } },
    { field: 'user_agent', headerName: t('User agent'), width: 400, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            size="small"
            to={`/dashboard/feedbacks/${cell.id}`}
          >
            {cell.row.userAgent}
          </Link>
        )
      }, },
      { field: 'message', headerName: t('Message'), flex: 1, filterable: false },
  ];

  return (<Page title={t('Feedbacks')}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>{t('Feedbacks')}</Typography>
      </Stack>

      <BaseTable
        columns={columns}
        loadCallback={async (query, page, pageSize, orderBy) => {
          try {
            return await api.feedbacks.list(query, page, pageSize, [], orderBy)
          } catch (e) {
            console.log('Failed to load feedbacks', e)
            return []
          }
        }}
      />
    </Container>
  </Page>);

}