// component
import Iconify                                                              from '../../components/Iconify';
import {
  USER_ROLE_APP_ADMIN,
  USER_ROLE_APP_EDITOR,
  USER_ROLE_NEWS_EDITOR,
  USER_ROLE_SUPER_ADMIN
} from '../../utils/consts'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // { title: 'dashboard', path: '/dashboard/app', icon: getIcon('eva:pie-chart-2-fill'), },
  {
    title: 'selectors',
    path: '/dashboard/selectors',
    icon: getIcon('eva:color-palette-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR]
  },
  {
    title: 'cars',
    path: '/dashboard/cars',
    icon: getIcon('eva:car-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR],
    requiredPart: 'cars'
  },
  {
    title: 'news',
    path: '/dashboard/news',
    icon: getIcon('eva:file-text-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR, USER_ROLE_NEWS_EDITOR]
  },
  {
    title: 'notifications',
    path: '/dashboard/notifications',
    icon: getIcon('eva:bell-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR, USER_ROLE_NEWS_EDITOR]
  },
  {
    title: 'feedback',
    path: '/dashboard/feedbacks',
    icon: getIcon('eva:email-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR]
  },
  {
    title: 'brands',
    path: '/dashboard/brands',
    icon: getIcon('eva:bookmark-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]
  },
  {
    title: 'car brands',
    path: '/dashboard/car-brands',
    icon: getIcon('eva:award-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN],
    requiredPart: 'cars'
  },
  { divider: true, key: 'key-divider-0' },
  {
    title: 'regions',
    path: '/dashboard/regions',
    icon: getIcon('eva:globe-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN]
  },
  {
    title: 'langs',
    path: '/dashboard/langs',
    icon: getIcon('eva:text-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN],
  },
  { divider: true, key: 'key-divider-1' },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
    accessForRoles: [USER_ROLE_SUPER_ADMIN],
  },
];

export default navConfig;
