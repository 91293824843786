import * as React                from 'react'
import { useContext, useEffect } from 'react'
import Box                       from '@mui/material/Box'
import Button                    from '@mui/material/Button'
import Dialog                    from '@mui/material/Dialog'
import DialogActions             from '@mui/material/DialogActions'
import DialogContent             from '@mui/material/DialogContent'
import DialogTitle               from '@mui/material/DialogTitle'
import FormControl               from '@mui/material/FormControl'
import FormControlLabel          from '@mui/material/FormControlLabel'
import InputLabel                from '@mui/material/InputLabel'
import MenuItem                  from '@mui/material/MenuItem'
import Select                    from '@mui/material/Select'
import Switch                    from '@mui/material/Switch'
import { t }                     from 'i18next'
import Iconify                   from './Iconify'
import { useApi }                from '../utils/api'
import {
  Avatar,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography
}                      from '@mui/material'
import useApps         from '../hooks/useApps'
import { AppContext }  from '../App'
import { TEXTURES }    from '../utils/consts'
import { lab2rgb }     from '../utils/colors'
import BaseLangSelect  from './base/BaseLangSelect'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

export default function CopySelectorDialog( { originSelector } ) {

  const api = useApi()
  const [apps] = useApps()
  const { currentApp } = useContext(AppContext)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false);
  const [lang, setLang] = React.useState()
  const [checkAll, setCheckAll] = React.useState(true)
  const [selectDestinationSelector, setSelectDestinationSelector] = React.useState(false);
  const [originalFoils, setOriginalFoils] = React.useState([]);
  const [checkedFoils, setCheckedFoils] = React.useState([])
  const [name, setName] = React.useState(originSelector?.name ?? '');
  const [destinationApp, setDestinationApp] = React.useState(currentApp);
  const [destinationSelectors, setDestinationSelectors] = React.useState([]);
  const [selectedDestinationSelector, setSelectedDestinationSelector] = React.useState(null);

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  const handleFoilToggle = (foilId) => () => {
    const currentIndex = checkedFoils.indexOf(foilId);
    const newChecked = [...checkedFoils];

    if (currentIndex === -1) {
      newChecked.push(foilId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFoils(newChecked);
  };

  const handleCopy = async (e) => {
    if (selectDestinationSelector && selectedDestinationSelector == null) {
      return;
    }
    let destinationSelectorId = selectDestinationSelector ? selectedDestinationSelector.id : null
    let destinationSelectorName = selectDestinationSelector ? null : name
    try {
      let copiedSelector = await api.selector.copy(originSelector.id, checkedFoils, destinationSelectorName, destinationApp.id, destinationSelectorId)
      enqueueSnackbar(t('copy-created-msg', { name: copiedSelector.name }), {
        variant: 'success',
        action: (snackbarId) => (
          <>
            <Button variant={'text'} color={'primary'} onClick={() => {
              navigate(`/dashboard/selectors/item/${copiedSelector.id}`)
            }}>
              {t('View')}
            </Button>
          </>
        )
      })
      setOpen(false)
    } catch (e) {
      enqueueSnackbar(t('Copy selector failed'), {
        variant: 'error'
      })
      console.log(e)
    }
  }

  useEffect(() => {
    (async () => {
      if (!originSelector?.id) {
        return
      }
      let { data } = await api.foil.list(originSelector?.id, null, 0, null, ['textures'], ['position', 'ASC'])
      setOriginalFoils(data)
      // default set all foils checked
      setCheckedFoils(data.map(it => it.id))
    })()
  }, [originSelector])

  useEffect(() => {
    (async () => {
      let { data } = await api.selector.list(destinationApp.id, null, 0, null, ['brand'], ['position', 'ASC'])
      setDestinationSelectors(data)
    })()
  }, [destinationApp])

  const buildThumbnail = (foil) => {
    const height = 24;
    const width = 24;
    let thumbnail = foil.textures?.filter(t => t.type === TEXTURES.color_fan_thumbnail)?.pop()
    if (thumbnail) {
      return <Avatar src={thumbnail.media.url} alt={thumbnail.type}
                     sx={{ width: width, height: height }} />
    }
    thumbnail = foil.textures?.filter(t => t.type === TEXTURES.color_card_thumbnail)?.pop()
    if (thumbnail) {
      return <Avatar src={thumbnail.media.url} alt={thumbnail.type}
                     sx={{ width: width, height: height }} />
    }
    let rgb = lab2rgb(foil.lab)
    return <Box sx={{ width: width, height: height, borderRadius: '50%', bgcolor: `rgb(${rgb[0]},${rgb[1]},${rgb[2]})` }} />
  }

  const toggleCheckAll = (e) => {
    if (!checkAll) {
      setCheckedFoils(originalFoils.map(it => it.id))
    } else {
      setCheckedFoils([])
    }
    setCheckAll(!checkAll)
  }

  const checkAllListItem = () => (<ListItem key={'check-all'} disablePadding>
    <ListItemButton onClick={toggleCheckAll}>
      <ListItemText primary={t('Check all')} />
      <ListItemIcon>
        <Checkbox
          edge="end"
          onClick={toggleCheckAll}
          checked={checkAll}
        />
      </ListItemIcon>
    </ListItemButton>
  </ListItem>)

  const buildOriginalFoils = () => {
    return (
      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {checkAllListItem()}
        {originalFoils?.map(foil => (
          <ListItem key={foil.id} disablePadding>
            <ListItemButton
              onClick={handleFoilToggle(foil.id)}
            >
              <ListItemAvatar>
                {buildThumbnail(foil)}
              </ListItemAvatar>
              <ListItemText primary={foil.name[lang] ?? (`-- ${t('not provided')} --`)} />
              <ListItemIcon>
                <Checkbox
                  edge="end"
                  checked={checkedFoils.indexOf(foil.id) !== -1}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>)
  }

  return (
    <React.Fragment>
      <Button variant={'text'}
              color={'primary'}
              onClick={handleClickOpen}
              startIcon={<Iconify icon="eva:copy-fill" />}>
        {t('Copy selector & foils')}
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{t('Copy selector & foils')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack direction={'column'}>
                <TextField
                  id="field-name"
                  label={t('Selector name')}
                  fullWidth
                  value={name}
                  disabled={selectDestinationSelector}
                  onChange={(e) => { setName(e.target.value) }}
                  variant="standard"
                  sx={{ mb: 2 }}
                />
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="subtitle2" component={'div'}>{t('Select foils')}</Typography>
                  <BaseLangSelect onLangChanged={(lang) => {
                    setLang(lang?.code)
                  }}/>
                </Stack>
                {buildOriginalFoils()}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction={'column'} spacing={2}>
                <FormControl variant={'standard'}>
                  <InputLabel htmlFor="destination-app">{t('Destination app')}</InputLabel>
                  <Select
                    value={destinationApp?.id}
                    onChange={(e) => {
                      let destApp = apps?.find(app => app.id === e.target.value)
                      setDestinationApp(destApp)
                    }}
                    label="destinationApp"
                    inputProps={{ name: 'destinationApp', id: 'destination-app', }}
                  >
                    {apps && apps.map(app => <MenuItem key={`app-${app.id}`} value={app.id}>{app.name}</MenuItem> )}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch checked={selectDestinationSelector} onChange={(e) => { setSelectDestinationSelector(e.target.checked) }} />
                  }
                  labelPlacement={'start'}
                  label={t('Select destination selector')}
                />
                {selectDestinationSelector && <FormControl variant={'standard'} error={selectDestinationSelector && selectedDestinationSelector == null}>
                  <InputLabel htmlFor="destination-selector">{t('Destination selector')}</InputLabel>
                  <Select
                    value={selectedDestinationSelector?.id ?? ''}
                    onChange={(e) => {
                      let destSelector = destinationSelectors?.find(it => it.id === e.target.value)
                      setSelectedDestinationSelector(destSelector)
                    }}
                    label="destinationSelector"
                    inputProps={{ name: 'destinationSelector', id: 'destination-selector', }}
                  >
                    {destinationSelectors && destinationSelectors.map(selector =>
                      (<MenuItem key={`selector-${selector.id}`} value={selector.id}>{`${selector.name} - ${selector.brand.name}`}</MenuItem>)
                    )}
                  </Select>
                </FormControl>}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={handleCopy}>{t('Copy')}</Button>
          <Button onClick={handleClose}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
