import Page                           from '../components/Page'
import { t }                          from 'i18next'
import {
  FormControl, FormHelperText,
  Grid,
  Input,
  InputLabel, Link, Typography,
} from '@mui/material'
import { useNavigate, useParams }     from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../utils/api'
import { useSnackbar }                from 'notistack'
import BaseForm                       from './BaseForm'
import useRegions                     from '../hooks/useRegions'
import { Editor }                     from '@tinymce/tinymce-react'
import { TINY_MCE_API_KEY }           from '../utils/consts'
import Checkbox                       from '@mui/material/Checkbox'
import FormControlLabel               from '@mui/material/FormControlLabel'

export default function Region () {

  const { regionId } = useParams()
  const api = useApi()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [_, reloadRegions] = useRegions()

  const [values, setValues] = useState({
    name: '',
    code: '',
    icon: '',
    active: false,
    contactUs: '',
    email: '',
    discount: '',
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  useEffect(() => {
    (async () => {
      if (!isNaN(regionId)) {
        let region = await api.region.detail(regionId)
        setValues({
          name: '',
          code: '',
          icon: '',
          active: false,
          contactUs: '',
          email: '',
          discount: '',
          ...region
        })
      }
    })()
  }, [regionId])

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    try {
      let region = await api.region.save(data)
      reloadRegions() // reload regions after regions are changed
      navigate(`/dashboard/regions/${region.id}`)
      enqueueSnackbar(t('Region saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save region'), {
        variant: 'error'
      })
    }
  }

  const onDelete = (id) => {
    api.region.delete(regionId).then(() => {
      enqueueSnackbar(t('Region deleted'), {
        variant: 'success'
      })
      reloadRegions() // reload regions after regions are changed
      navigate('/dashboard/regions')
    }).catch(error => {
      enqueueSnackbar(t('Failed to delete region'), {
        variant: 'error'
      })
    })
  }

  return (<Page title={t('Region')}>
    <BaseForm
      id={isNaN(regionId) ? 0 : parseInt(regionId)}
      formTitle={t('Region')}
      values={values}
      onSubmit={onSubmit}
      onDelete={onDelete}
    >
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="region-name">{t('Name')}</InputLabel>
          <Input
            id="region-name"
            value={values.name}
            onChange={handleChange('name')}
          />
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="region-code">{t('Code')}</InputLabel>
          <Input
            id="region-code"
            value={values.code}
            onChange={handleChange('code')}
          />
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="icon">{t('Icon')}</InputLabel>
          <Input
            id="icon"
            value={values.icon}
            onChange={handleChange('icon')}
          />
          <FormHelperText>
            {t('By default is used "flagpack:" + region code above')}
            <Link
              target={'_blank'}
              href={'http://icon-sets.iconify.design/flagpack'}>{t('Check if flag exists')}</Link>
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={<Checkbox value={1} color="primary" checked={values.active} onChange={(e) => handleChange('active')({ target: { value: e.target.checked } })} />}
            label={t('Active')}
          />
        </FormControl>
        <div>
          <Typography variant={'caption'}>{t('Contact us')}</Typography>
          <Editor
            apiKey={TINY_MCE_API_KEY}
            value={values.contactUs}
            onEditorChange={(newValue, editor) => { handleChange('contactUs')({ target: { value: newValue } }) }} />
        </div>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="region-email">{t('Email')}</InputLabel>
          <Input
            id="region-email"
            value={values.email}
            type={'email'}
            onChange={handleChange('email')}
          />
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="region-discount">{t('Discount %')}</InputLabel>
          <Input
            id="region-discount"
            value={values.discount}
            type={'number'}
            step={1}
            max={100}
            min={0}
            onChange={handleChange('discount')}
          />
        </FormControl>
      </Grid>
    </BaseForm>
  </Page>)
}