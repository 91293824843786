import Page                                       from '../components/Page'
import { t }                                      from 'i18next'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField
}                                                 from '@mui/material'
import { useNavigate, useParams }                 from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { useApi }                                 from '../utils/api'
import BaseLangSelect                             from '../components/base/BaseLangSelect'
import { BrandAutocomplete, RegionsCheckboxes }   from '../components/Checkboxes'
import BaseForm           from './BaseForm'
import ImageFileUpload    from '../components/ImageFileUpload'
import { useSnackbar }    from 'notistack'
import SelectorFoils      from '../sections/selector/SelectorFoils'
import DocumentFileUpload from '../components/DocumentFileUpload'
import Loader             from '../components/Loader'
import CopySelectorDialog from '../components/CopySelectorDialog'
import FoilsDialog        from '../sections/foil/FoilsDialog'
import ExportSelectors    from '../components/ExportSelectors'
import ImportSelector     from '../components/ImportSelector'
import { AppContext }     from '../App'
import useBrands          from '../hooks/useBrands'

const defaultValues = {
  version: 1,
  name: '',
  state: 'draft',
  brandId: null,
  description: {},
  descriptionTechnical: {},
  overview: {},
  howToUse: {},
  regionIds: [],
  thumbnail: null,
  selectorDatasheets: {},
}

export default function Selector () {

  const { selectorId } = useParams()
  const { currentApp } = useContext(AppContext)
  const navigate = useNavigate()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const [lang, setLang] = useState()
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState(defaultValues)
  const [rawSelector, setRawSelector] = useState(null)
  const [selectedFoilIndex, setSelectedFoilIndex] = useState(null)
  const [filteredFoilIds, setFilteredFoilIds] = useState([])
  const [reloadCounter, setReloadCounter] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [brands] = useBrands()

  useEffect(() => {
    (async () => {
      setLoading(true)
      if (!isNaN(selectorId)) {
        let selector = await api.selector.detail(selectorId, ['selectorDatasheets', 'thumbnail'])
        let parsedSelectorDatasheets = {}
        selector?.selectorDatasheets?.forEach(it => {
          parsedSelectorDatasheets[it.lang] = it.media
        })
        setValues({
          ...defaultValues,
          ...selector,
          selectorDatasheets: parsedSelectorDatasheets
        })
        setRawSelector(selector)
      }
      setLoading(false)
    })()
  }, [selectorId])

  useEffect(() => {
    if (!!rawSelector?.id && rawSelector.appId !== currentApp.id) {
      navigate('/dashboard/selectors')
    }
  }, [currentApp])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const handleTranslatedValue = (prop) => (event) => {
    let property = values[prop]
    property[lang] = event.target.value
    setValues({ ...values, [prop]: property });
  }

  const translatedValue = (property) => {
    return (values[property] ?? {})[lang] ?? ''
  }

  const selectorFormHeader = (
    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
      {!isNaN(selectorId) && <CopySelectorDialog originSelector={rawSelector} />}
      {!isNaN(selectorId) && <ExportSelectors selectorIds={() => [selectorId]} isExportFoils={true} />}
      {!isNaN(selectorId) && <ImportSelector selectorId={selectorId} />}
      <Box flex={1}/>
      <BaseLangSelect onLangChanged={(lang) => {
        setLang(lang?.code)
      }}/>
    </Stack>
  )

  return (
    <Page title={t('Selector')}>
      <Loader loading={loading}>
      <BaseForm
        id={isNaN(selectorId) ? 0 : parseInt(selectorId)}
        formTitle={t('Selector')}
        header={selectorFormHeader}
        onDelete={async (id) => {
          try {
            await api.selector.delete(id)
            navigate(`/dashboard/selectors`)
            enqueueSnackbar(t('Selector deleted'), {
              variant: 'success'
            })
          } catch (e) {
            enqueueSnackbar(t('Failed to delete selector'), {
              variant: 'error'
            })
          }
        }}
        onSubmit={async (e) => {
          e.preventDefault()
          let requestValues = {
            ...values,
            selectorDatasheets: Object.keys(values.selectorDatasheets).map(lang => ({ lang: lang, mediaId: values.selectorDatasheets[lang]?.id ?? null })).filter(it => it.mediaId !== null)
          }
          try {
            let selector = await api.selector.save(requestValues)
            navigate(`/dashboard/selectors/item/${selector.id}`)
            enqueueSnackbar(t('Selector saved'), {
              variant: 'success'
            })
          } catch (e) {
            enqueueSnackbar(t('Failed to save selector'), {
              variant: 'error'
            })
          }
        }}
        values={values}>
        <Grid item xs={12} md={8}>
          <TextField
            id="field-name"
            label={t('Name')}
            fullWidth
            value={values.name}
            onChange={handleChange('name')}
            variant="standard"
            sx={{ mb: 2 }}
          />
          <BrandAutocomplete
            brands={brands ?? []}
            selectedBrandId={values?.brandId ?? null}
            onSelectedBrandIdChanged={(brandId) => {
              handleChange('brandId')({ target: { value: brandId } })
            }} />
          <FormControl sx={{ mt: 2 }}>
            <FormLabel id="label-state">{t('State')}</FormLabel>
            <RadioGroup row aria-labelledby="label-state" name="state" value={values.state} onChange={handleChange('state')}>
              <FormControlLabel value="draft" control={<Radio />} label={t('Draft')} />
              <FormControlLabel value="published" control={<Radio />} label={t('Published')} />
              <FormControlLabel value="deleted" control={<Radio />} label={t('Deleted')} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true}>{t('Thumbnail')}</InputLabel>
            <ImageFileUpload data={values.thumbnail ? [values.thumbnail] : []} onDataChanged={(newThumbnails) => {
              let thumbnail = newThumbnails?.pop()
              setValues((val) => ({
                ...val,
                thumbnail: thumbnail ?? null,
                thumbnailMediaId: thumbnail?.id ?? null,
              }));
            }} />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-description"
            label={t('Description')}
            multiline
            fullWidth
            maxRows={4}
            value={translatedValue('description')}
            onChange={handleTranslatedValue('description')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-description-technical"
            label={t('Technical description')}
            multiline
            fullWidth
            maxRows={4}
            value={translatedValue('descriptionTechnical')}
            onChange={handleTranslatedValue('descriptionTechnical')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-overview"
            label={t('Overview')}
            multiline
            fullWidth
            maxRows={4}
            value={translatedValue('overview')}
            onChange={handleTranslatedValue('overview')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-how-to-use"
            label={t('How to use')}
            multiline
            fullWidth
            maxRows={4}
            value={translatedValue('howToUse')}
            onChange={handleTranslatedValue('howToUse')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DocumentFileUpload title={t('Technical datasheet PDF')}
                              accept={'application/pdf'}
                              data={translatedValue('selectorDatasheets') ? [translatedValue('selectorDatasheets')] : []}
                              onDataChanged={(data) => {
                                handleTranslatedValue('selectorDatasheets')( { target: { value: data.length === 1 ? data[0] : null } } )
                              }} />
        </Grid>
        <Grid item xs={12}>
          <RegionsCheckboxes
            handleProperty={'id'}
            selectedRegionCodes={values.regionIds}
            onSelectedRegionCodesChange={(regionIds) => {
              handleChange('regionIds')({ target: { value: regionIds } })
            }} />
        </Grid>
      </BaseForm>
      {!isNaN(selectorId) && <SelectorFoils selectorId={parseInt(selectorId)}
                                            reloadCounter={reloadCounter}
                                            onFoilClicked={(selectedIndex, filteredFoilIds) => {
                                              setSelectedFoilIndex(() => selectedIndex)
                                              setFilteredFoilIds(() => filteredFoilIds)
                                              setOpenDialog(() => true)
                                            }}  />}
        {!isNaN(selectorId) && <FoilsDialog
          open={openDialog}
          selectorId={parseInt(selectorId)}
          selectedIndex={selectedFoilIndex}
          setSelectedIndex={setSelectedFoilIndex}
          foilIds={filteredFoilIds}
          onDelete={(foilId) => {
            let index = filteredFoilIds.indexOf(foilId)
            if (index > -1) {
              filteredFoilIds.splice(index, 1)
            }
            setReloadCounter(prev => prev + 1)
            if (filteredFoilIds.length === 0) {
              // close dialog
              setOpenDialog(false)
            }
            return filteredFoilIds.length !== 0
          }}
          handleClose={(e) => {
            setSelectedFoilIndex(() => null)
            setOpenDialog(false)
            setReloadCounter(prev => prev + 1)
          }} />}
      </Loader>
    </Page>
  )
}