import Page                           from '../components/Page'
import { t }                          from 'i18next'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Switch
}                                     from '@mui/material'
import { useNavigate, useParams }     from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../utils/api'
import { useSnackbar }                from 'notistack'
import BaseForm                       from './BaseForm'

export default function Brand() {

  const { brandId } = useParams()
  const api = useApi()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [values, setValues] = useState({
    name: '',
    active: true,
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  useEffect(() => {
    (async () => {
      if (!isNaN(brandId)) {
        let brand = await api.brand.detail(brandId)
        setValues({
          name: '',
          active: true,
          ...brand
        })
      }
    })()
  }, [brandId])

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    try {
      let brand = await api.brand.save(data)
      navigate(`/dashboard/brands/${brand.id}`)
      enqueueSnackbar(t('Brand saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save brand'), {
        variant: 'error'
      })
    }
  }

  const onDelete = (id) => {
    api.brand.delete(brandId).then(() => {
      enqueueSnackbar(t('Brand deleted'), {
        variant: 'success'
      })
      navigate('/dashboard/brands')
    }).catch(error => {
      enqueueSnackbar(t('Failed to delete brand'), {
        variant: 'error'
      })
    })
  }

  return (<Page title={t('Brand')}>
    <BaseForm
      id={isNaN(brandId) ? 0 : parseInt(brandId)}
      formTitle={t('Brand')}
      values={values}
      onSubmit={onSubmit}
      onDelete={onDelete}
    >
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="brand-name">{t('Name')}</InputLabel>
          <Input
            id="brand-name"
            value={values.name}
            onChange={handleChange('name')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch
            checked={values.active}
            onChange={(e) => handleChange('active')({ target: { value: !values.active } })}
          />}
          label={t('Active')}/>
      </Grid>
    </BaseForm>
  </Page>)
}