export const TINY_MCE_API_KEY = 'jgn4e9g10khjna5r9t8z6qwjkmxs1fe6q382m2c8jlhvbrde'
const DEFAULT_URL = '/dashboard/selectors'
const DEFAULT_URL_NEWS_EDITOR = '/dashboard/news'
export const getDefaultURL = (userRole) => {
  return userRole === 'news_editor' ? DEFAULT_URL_NEWS_EDITOR : DEFAULT_URL
}

export const MATERIALS = {
  basic_color: 'basic_color',
  printed: 'printed',
  '3d_effect': '3d_effect',
  flock: 'flock',
  translucent: 'translucent',
  transparent: 'transparent',
  glitter: 'glitter',
  brushed_metal: 'brushed_metal',
  metalized: 'metalized',
  iridescent: 'iridescent',
  carbon: 'carbon',
  carbon_2: 'carbon_2',
  reflex: 'reflex',
  fluorescent: 'fluorescent',
  matt_metal: 'matt_metal',
}

export const TEXTURES = {
  color_fan_thumbnail: 'color_fan_thumbnail',
  color_card_thumbnail: 'color_card_thumbnail',
  diffuse: 'diffuse',
  diffuse_2: 'diffuse_2',
  normal_map: 'normal_map',
  glossy: 'glossy',
  glitter: 'glitter',
  anisotropic: 'anisotropic',
  iridescent: 'iridescent',
}

export const MATERIAL_TEXTURES = {
  basic_color: [],
  printed: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse ],
  '3d_effect': [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.iridescent ],
  flock: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.anisotropic ],
  translucent: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  transparent: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  glitter: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.iridescent ],
  brushed_metal: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  metalized: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  iridescent: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.diffuse_2, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  carbon: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  carbon_2: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.diffuse_2, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  reflex: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  fluorescent: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.anisotropic, TEXTURES.iridescent ],
  matt_metal: [ TEXTURES.color_fan_thumbnail, TEXTURES.color_card_thumbnail, TEXTURES.diffuse, TEXTURES.normal_map, TEXTURES.glossy, TEXTURES.glitter, TEXTURES.iridescent ],
}

export const ADHESIVES = ['permanent', 'semipermanent', 'ultraremovable', 'super_tac', 'thermal_transfer', 'electrostatic', 'magnetic']
export const TRANSPARENCY = ['opaque', 'translucent', 'transparent', 'block_out']
export const PLACE_OF_USE = ['vehicles', 'exhibitions', 'walls', 'windows', 'furniture', 'light_box', 'flexible_substrates', 'safety_markin', 'traffic_signs', 'textile']
export const FINISHING = ['m_matt', 'g_glossy', 'basic_color', '3d_effect', 'glitter', 'iridescent', 'brushed_metal', 'matt_metal', 'metalized', 'flock', 'printed', 'carbon', 'reflex', 'fluorescent']
export const ENVIRONMENT = ['exterior', 'interior']

export const USER_ROLE_SUPER_ADMIN = 'super_admin'
export const USER_ROLE_APP_ADMIN = 'app_admin'
export const USER_ROLE_APP_EDITOR = 'app_editor'
export const USER_ROLE_APP_USER = 'app_user'
export const USER_ROLE_NEWS_EDITOR = 'news_editor'
export const USER_ROLES = [ USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR, USER_ROLE_APP_USER, USER_ROLE_NEWS_EDITOR ]

export const APP_PART_CARS = 'cars'

export const CAR_THUMBNAIL_SMALL = 'small'
export const CAR_THUMBNAIL_BIG = 'big'

export const CAR_TYPE_COUPE = 'coupe'
export const CAR_TYPE_SEDAN = 'sedan'
export const CAR_TYPE_LIMOUSINE = 'limousine'
export const CAR_TYPE_SUV = 'suv'
export const CAR_TYPE_CROSSOVER = 'crossover'
export const CAR_TYPE_MPV = 'mpv'
export const CAR_TYPE_SPORTS_CAR = 'sports_car'
export const CAR_TYPE_VAN = 'van'

export const CAR_TYPES = [CAR_TYPE_COUPE, CAR_TYPE_SEDAN, CAR_TYPE_LIMOUSINE, CAR_TYPE_SUV, CAR_TYPE_CROSSOVER, CAR_TYPE_MPV, CAR_TYPE_SPORTS_CAR, CAR_TYPE_VAN,]

export const STATE_DRAFT = 'draft'
export const STATE_PUBLISHED = 'published'
export const STATE_DELETED = 'deleted'