import PropTypes                                                        from 'prop-types'
import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, } from '@mui/material'
import React, { useEffect, useState }                                   from 'react'
import { t }                                                            from 'i18next'
import { MATERIAL_TEXTURES }                                            from '../../utils/consts'
import Iconify                                                          from '../../components/Iconify'
import { styled }                                                       from '@mui/material/styles'
import { useApi }                                                       from '../../utils/api'
import { useSnackbar }                                                  from 'notistack'

const Input = styled('input')({
  display: 'none',
})

const Image = styled('img')({
  maxHeight: '100%',
  objectFit: 'cover',
  height: 'auto',
  flexGrow: 1,
})

export default function FoilTextures({ material, defaultValues, onValuesChanged }) {

  const TEXTURE_LANG = {
    color_fan_thumbnail: t('Color fan thumbnail'),
    color_card_thumbnail: t('Color card thumbnail'),
    diffuse: t('Diffuse'),
    diffuse_2: t('Diffuse 2'),
    normal_map: t('Normal map'),
    glossy: t('Glossy'),
    glitter: t('Glitter'),
    anisotropic: t('Anisotropic'),
    iridescent: t('Iridescent'),
  }

  const api = useApi()
  const [values, setValues] = useState(defaultValues)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    onValuesChanged(values)
  }, [values])

  const onFilesChanged = (textureKey) => (files) => {
    if (files.length !== 1) {
      return
    }
    const formData = new FormData()
    const file = files[0]
    formData.append(file.name, file, file.name)

    api.media.upload(formData).then((response) => {
      setValues(prevState => {
        let result = [...prevState.filter(it => it.type !== textureKey)]
        result.push({
          id: null,
          type: textureKey,
          media: response.data[0]
        })
        return result
      })

    }).catch(error => {
      enqueueSnackbar(t(`Failed to upload file ${error.error}`), {
        variant: 'error'
      })
    })
  }

  const handleIconClick = (textureKey) => (e) => {
    let textureMedia = values.find(it => it.type === textureKey)
    if (!textureMedia) {
      // ignore open file input -> handled using <label><input></label> below
      return
    }
    // handle only delete files
    setValues((prevValues) => prevValues.filter(it => it.type !== textureKey))
  }

  return (
    <Box spacing={2}>
      <ImageList cols={5} gap={16} rowHeight={132}>
        {MATERIAL_TEXTURES[material]?.map(textureKey => {
          let textureMedia = values.find(it => it.type === textureKey)
          return (
            <ImageListItem key={textureKey}
                           onDragOver={(event) => {
                             event.preventDefault();
                           }}
                           onDrop={e => {
                             e.preventDefault();
                             onFilesChanged(textureKey)(e.dataTransfer.files)
                           }}>
              <Image
                src={textureMedia?.media?.url ?? '/static/no-photo-available.png'}
                srcSet={textureMedia?.media?.url ?? '/static/no-photo-available.png'}
                alt={TEXTURE_LANG[textureKey]}
                loading="lazy"
              />
              <ImageListItemBar
                title={TEXTURE_LANG[textureKey]}
                actionIcon={
                  <IconButton sx={{ color: 'rgba(255, 255, 255, 0.84)' }}
                              onClick={handleIconClick(textureKey)}
                  >
                    {
                      textureMedia ? <Iconify icon={'eva:trash-2-outline'} /> : (<label style={{ display: 'flex', cursor: 'inherit' }}>
                        <Input accept="image/*"
                               type="file"
                               multiple={false}
                               onChange={(e) => onFilesChanged(textureKey)(e.target.files)}/>
                        <Iconify icon={'eva:plus-square-outline'} />
                      </label>)
                    }
                  </IconButton>
                }
              />

            </ImageListItem>
          )
        })}
      </ImageList>
  </Box>)
}

FoilTextures.proTypes = {
  material: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onValuesChanged: PropTypes.func.isRequired,
}