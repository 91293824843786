import Page                           from '../components/Page'
import { t }                          from 'i18next'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Switch
}                                     from '@mui/material'
import { useNavigate, useParams }     from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../utils/api'
import { useSnackbar }                from 'notistack'
import BaseForm                       from './BaseForm'
import ImageFileUpload                from '../components/ImageFileUpload'
import useCarBrands                   from '../hooks/useCarBrands'

export default function CarBrand () {

  const { brandId } = useParams()
  const api = useApi()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [_, reloadCarBrands] = useCarBrands()

  const [values, setValues] = useState({
    name: '',
    active: true,
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  useEffect(() => {
    (async () => {
      if (!isNaN(brandId)) {
        let brand = await api.carBrand.detail(brandId)
        setValues({
          name: '',
          active: true,
          ...brand
        })
      }
    })()
  }, [brandId])

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
      logoMediaId: values.logo?.id ?? null,
    }
    delete data.logo
    try {
      let brand = await api.carBrand.save(data)
      navigate(`/dashboard/car-brands/${brand.id}`)
      enqueueSnackbar(t('Car brand saved'), {
        variant: 'success'
      })
      reloadCarBrands()
    } catch (e) {
      enqueueSnackbar(t('Failed to save car brand'), {
        variant: 'error'
      })
    }
  }

  const onDelete = (id) => {
    api.carBrand.delete(brandId).then(() => {
      enqueueSnackbar(t('Car brand deleted'), {
        variant: 'success'
      })
      reloadCarBrands()
      navigate('/dashboard/car-brands')
    }).catch(error => {
      enqueueSnackbar(t('Failed to delete car brand'), {
        variant: 'error'
      })
    })
  }

  return (<Page title={t('Car brand')}>
    <BaseForm
      id={isNaN(brandId) ? 0 : parseInt(brandId)}
      formTitle={t('Brand')}
      values={values}
      onSubmit={onSubmit}
      onDelete={onDelete}
    >
      <Grid item xs={12}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="brand-name">{t('Name')}</InputLabel>
          <Input
            id="brand-name"
            value={values.name}
            onChange={handleChange('name')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={<Switch
            checked={values.active}
            onChange={(e) => handleChange('active')({ target: { value: !values.active } })}
          />}
          label={t('Active')}/>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink={true}>{t('Logo')}</InputLabel>
          <ImageFileUpload data={values?.logo ? [values.logo] : []} cols={4} multiple={false} onDataChanged={(mediaArray) => {
            handleChange('logo') ({ target: { value: mediaArray[0] ?? null } })
          }} />
        </FormControl>
      </Grid>
    </BaseForm>
  </Page>)
}