import { Button, Card, CardActions, CardContent, Container, Stack, Typography } from '@mui/material'
import { t }                                                                    from 'i18next'
import Page                                                                     from '../components/Page'
import { useParams }                                                            from 'react-router-dom'
import React, { useEffect, useState }                                           from 'react'
import { useApi }                                                               from '../utils/api'
import Iconify                                                                  from '../components/Iconify'
import { dateToUserString }                                                     from '../utils/dateutils'

export default function Feedback() {

  const { feedbackId } = useParams()
  const api = useApi()
  const [feedback, setFeedback] = useState({
    id: null,
    userAgent: '',
    message: '',
    seen: null,
    created: null,
  })

  useEffect(() => {
    (async () => {
      let feedback = await api.feedbacks.detail(feedbackId)
      setFeedback(feedback)
    })()
  }, [feedbackId])

  const markAsSeen = async () => {
    let newFeedback = await api.feedbacks.markAsSeen(feedbackId, feedback.seen == null)
    setFeedback(newFeedback)
  }

  return (
    <Page title={t('Feedback')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>{t('Feedback')}</Typography>
        </Stack>
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {feedback.created && dateToUserString(feedback.created)}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {feedback.userAgent}
            </Typography>
            <Typography variant="body2">
              {feedback.message}
            </Typography>
          </CardContent>
          <CardActions>
            {feedback?.seen == null && <Button
              onClick={markAsSeen}
              startIcon={<Iconify icon={'eva:checkmark-fill'} />}
              size="small">
              {t('Mark as seen')}
            </Button>}
          </CardActions>
        </Card>
      </Container>
    </Page>
  )
}