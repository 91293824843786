import Page                                                                                      from '../components/Page'
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Stack,
  TextField,
  Typography
}                            from '@mui/material'
import React, { useState }   from 'react'
import { t }                 from 'i18next'
import { RegionsCheckboxes } from '../components/Checkboxes'
import { useSnackbar }       from 'notistack'
import { useApi }            from '../utils/api'

export default function Notifications() {

  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [values, setValues] = useState({
    title: '',
    content: '',
    regionIds: [],
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }


  const onSubmit = (e) => {
    e.preventDefault()
    if (!values.title || !values.content || values.regionIds.length === 0) {
      // can't send empty notification
      enqueueSnackbar(t(`Notification can't be empty`), {
        variant: 'warning'
      })
      return
    }
    api.notification.send(values.title, values.content, values.regionIds).then(response => {
      enqueueSnackbar(t(`Notifications sent`), {
        variant: 'success'
      })
    }).catch(error => {
      enqueueSnackbar(t(`Failed to send notification`), {
        variant: 'error'
      })
    })
  }

  return (<Page title={t('Notifications')}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4" gutterBottom>{t('Notifications')}</Typography>
      </Stack>
      <Box component={'form'} onSubmit={onSubmit}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="news-title">{t('Title')}</InputLabel>
                <Input
                  id="news-title"
                  value={values.title}
                  onChange={handleChange('title')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="field-content"
                label={t('Content')}
                multiline
                fullWidth
                maxRows={4}
                value={values.content}
                onChange={handleChange('content')}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <RegionsCheckboxes
                handleProperty={'id'}
                selectedRegionCodes={values.regionIds}
                onSelectedRegionCodesChange={(regionIds) => {
                  handleChange('regionIds')({ target: { value: regionIds } })
                }} />
            </Grid>
          </Grid>
        </Card>
        <Box sx={{ mt: 2, px: 1 }}>
          <Button type={'submit'} variant={'contained'}>{t('Send')}</Button>
        </Box>
      </Box>
    </Container>
  </Page>)
}