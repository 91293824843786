import { useApi }                                       from '../utils/api'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext }                                   from '../App'

let apps
export default function useApps() {
  const { user } = useContext(AppContext)
  const api = useApi()
  const [local, setLocal] = useState(apps)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    apps = undefined
    setReload(reload => reload + 1)
  }, [])

  useEffect(() => {
    (async function() {
      if (user) {
        apps = (await api.app.list()).data
        setLocal(apps)
      }
    })();
  }, [reload, user])

  return [local, reloadFnc]
}