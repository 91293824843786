import React, { useState }                                   from 'react'
import { Avatar, Box, Button, Chip, Container, Link, Stack } from '@mui/material'
import BaseTable                                             from '../components/base/BaseTable'
import { useApi }                                            from '../utils/api'
import { Link as RouterLink }                                from 'react-router-dom'
import { useTranslation }                                    from 'react-i18next'
import { parseUTC, USER_DATE_TIME_FORMAT }                   from '../utils/dateutils'
import Typography                                            from '@mui/material/Typography'
import Iconify                                               from '../components/Iconify'
import Page                                                  from '../components/Page'
import { t }                                                 from 'i18next'
import { formatBytes }                                       from '../utils/format'
import Search                                                from '../components/Search'

export default function Cars () {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, filterable: false },
    { field: 'icon', headerName: t('Icon'), width: 80, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <img src={cell.row.icon?.url ?? '/static/no-photo-available.png'}  alt={cell.row.series} />
        )
      }, },
    { field: 'carBrand.name', headerName: t('Car brand'), width: 100, filterable: false, renderCell: (cell) => {
        return (
          <Typography variant={'caption'}>{cell.row.carBrand.name}</Typography>
        )
      }, },
    { field: 'series', headerName: t('Series'), flex: 1, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            size="small"
            to={`/dashboard/cars/item/${cell.id}`}
          >
            {cell.row.series ? cell.row.series : '---'}
          </Link>
        )
      }, },
    { field: 'state', headerName: t('State'), width: 100, filterable: false, renderCell: (cell) => {
        let state = cell.row.state
        return (
          <Chip size={'small'}
                variant={'outlined'}
                color={(state === 'deleted' && 'error') || (state === 'draft' && 'warning') || 'primary'}
                label={(state === 'deleted' && t('Deleted')) || (state === 'draft' && t('Draft')) || t('Published')} />
        )
      }, },
    { field: 'size', headerName: t('Size'), width: 100, filterable: false, renderCell: (cell) => {
        return (
          <Typography variant={'caption'}>{formatBytes(cell.row.size)}</Typography>
        )
      }, },
  ];

  return (<>
    <Page title={t('Cars')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>{t('Cars')}</Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/cars/item/create"
                  startIcon={<Iconify icon="eva:plus-fill"/>}>
            {t('Create car')}
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
          <Search query={query} onQueryChanged={(query) => setQuery(query)} title={t('Search car ...')} />
        </Stack>

        <BaseTable
          columns={columns}
          rowHeight={48}
          query={query}
          loadCallback={async (query, page, pageSize, orderBy) => {
            try {
              if (orderBy?.length > 0 && orderBy[0].length > 0 && orderBy[0][0] === 'carBrand.name') {
                orderBy.push(['series', orderBy[0][1]])
              }
              return await api.car.list(query ? query : null, page, pageSize, ['carBrand', 'icon'], orderBy)
            } catch (e) {
              console.log('Failed to load cars', e)
              return []
            }
          }}
        />
      </Container>
    </Page>
  </>)

}