import PropTypes                                                           from 'prop-types'
import { Box, Button, Card, CardHeader, Divider, Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink }                                              from 'react-router-dom'
import { dateToUserString }                                                from '../../utils/dateutils'
import { useContext, useEffect, useState }                                 from 'react'
import { t }                                                               from 'i18next'
import { useApi }                                                          from '../../utils/api'
import Iconify                                                             from '../../components/Iconify'
import { AppContext }                                                      from '../../App'

export function LastSelectorUpdates () {

  const api = useApi()
  const { currentApp } = useContext(AppContext)
  const [items, setItems] = useState([])

  useEffect(() => {
    (async () => {
      let { data } = await api.selector.list(null, null, 0, 7, ['thumbnail', 'brand'], [['modified', 'DESC']])
      setItems(data)
    })()
  }, [currentApp])

  return <CardUpdates
    title={t('Selector updates')}
    list={items}
    viewAllHref={'/dashboard/selectors'}
    getRowTexts={(item) => ({
      id: item.id,
      description: item.brand?.name,
      image: item.thumbnail.url,
      modified: item.modified ?? item.created,
      title: item.name,
      href: `/dashboard/selectors/item/${item.id}`,
    })}
  />
}


export function LastFoilsUpdates () {

  const api = useApi()
  const { currentApp } = useContext(AppContext)
  const [items, setItems] = useState([])

  useEffect(() => {
    (async () => {
      let { data } = await api.foil.list(null, null,0, 7, [], [['modified', 'DESC']])
      setItems(data)
    })()
  }, [currentApp])

  return <CardUpdates
    title={t('Foil updates')}
    list={items}
    viewAllHref={null}
    getRowTexts={(item) => ({
      id: item.id,
      image: null,
      modified: item.modified ?? item.created,
      title: Object.values(item.name).pop(),
      description: Object.values(item.selectorSubcategory).pop(),
      href: `/dashboard/selectors/${item.selectorId}/foil/${item.id}`,
    })}
  />
}

CardUpdates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  getRowTexts: PropTypes.func.isRequired,
}

function CardUpdates ({ title, subheader, list, getRowTexts, viewAllHref, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader}/>

      <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
        {list.map((item) => (
          <UpdateItem key={item.id} item={getRowTexts(item)}/>
        ))}
      </Stack>

      <Divider/>

      {viewAllHref && <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small"
                color="inherit"
                to={viewAllHref}
                component={RouterLink}
                endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'}/>}>
          {t('View all')}
        </Button>
      </Box>}
    </Card>
  )
}

// ----------------------------------------------------------------------

UpdateItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    image: PropTypes.string,
    modified: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
  }),
}

function UpdateItem ({ item }) {
  const { id, image, title, description, href, modified } = item

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {image && <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}/>}

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link component={RouterLink} color="inherit" variant="subtitle2" to={href} noWrap>
          {!!title ? title : `#${id}`}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {dateToUserString(modified)}
      </Typography>
    </Stack>
  )
}
