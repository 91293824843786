import { useApi }                                    from '../utils/api'
import { useCallback, useEffect, useState } from 'react'

let brands
export default function useBrands() {
  const api = useApi()
  const [local, setLocal] = useState(brands)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    brands = undefined
    setReload(reload => reload + 1)
  }, [])

  useEffect(() => {
    async function load() {
      brands = (await api.brand.list('', 0, 1000)).data
      setLocal(brands)
    }
    if (brands === undefined) {
      load()
    }
  }, [reload])

  return [local, reloadFnc]
}