import Iconify                           from './Iconify'
import React                             from 'react'
import { InputAdornment, OutlinedInput } from '@mui/material'
import PropTypes                         from 'prop-types'
import { styled }                        from '@mui/material/styles'

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Search(props) {

  const { query, onQueryChanged, title } = props

  return (<SearchStyle
    value={query}
    onChange={(e) => onQueryChanged(e.target.value) }
    placeholder={title}
    startAdornment={
      <InputAdornment position="start">
        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
      </InputAdornment>
    }
  />)
}

Search.propTypes = {
  query: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onQueryChanged: PropTypes.func.isRequired,
}