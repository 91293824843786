import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout                      from './layouts/dashboard'
//
import User                                 from './pages/User'
import NotFound                             from './pages/Page404.js'
import DashboardApp                         from './pages/DashboardApp'
import SignIn                               from './pages/SignIn'
import React, { useContext } from 'react'
import Users          from './pages/Users'
import News           from './pages/News'
import NewsDetail     from './pages/NewsDetail'
import { AppContext } from './App'
import SelectApp       from './pages/SelectApp'
import Selector                  from './pages/Selector'
import Selectors                 from './pages/Selectors'
import Brand                     from './pages/Brand'
import Brands                    from './pages/Brands'
import Regions                   from './pages/Regions'
import Region                    from './pages/Region'
import Langs                     from './pages/Langs'
import Lang      from './pages/Lang'
import Forbidden from './pages/Forbidden'
import Feedbacks from './pages/Feedbacks'
import Feedback  from './pages/Feedback'
import {
  APP_PART_CARS,
  getDefaultURL,
  USER_ROLE_APP_ADMIN,
  USER_ROLE_APP_EDITOR,
  USER_ROLE_NEWS_EDITOR,
  USER_ROLE_SUPER_ADMIN
}                    from './utils/consts'
import CarBrands     from './pages/CarBrands'
import CarBrand      from './pages/CarBrand'
import Cars          from './pages/Cars'
import Car           from './pages/Car'
import Notifications from './pages/Notifications'

// ----------------------------------------------------------------------

const ProtectedRoute = ({ children, accessForRoles }) => {

  const location = useLocation()
  const { user } = useContext(AppContext)

  if (user === null) {
    return <Navigate to="/sign-in" state={{ from: location }}/>
  }

  if (!!accessForRoles && accessForRoles.indexOf(user.role) === -1) {
    return <Navigate to={'/dashboard/forbidden'} state={{ from: location }} />
  }

  return (<React.Fragment>{children}</React.Fragment>)
}

const AppProtectedRoute = ({ children, requiredPart, ...others }) => {

  const location = useLocation()
  const { currentApp } = useContext(AppContext)

  if (currentApp == null) {
    return <ProtectedRoute><Navigate to="/select-app"/></ProtectedRoute>
  }

  if (!!requiredPart && (!currentApp.parts || currentApp.parts.indexOf(requiredPart) < 0)) {
    return <Navigate to={'/dashboard/forbidden'} state={{ from: location }} />
  }

  return (<React.Fragment><ProtectedRoute {...others}>{children}</ProtectedRoute></React.Fragment>)
}

function DefaultPageNavigate() {

  const { user } = useContext(AppContext)
  const defaultUrl = getDefaultURL(user?.role)

  return (<Navigate to={defaultUrl} replace={true} />)
}

export default function Router () {

  const APP_ADMIN_ROLES = [USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN, USER_ROLE_APP_EDITOR]

  return useRoutes([
    {
      path: '/dashboard',
      element: <AppProtectedRoute><DashboardLayout/></AppProtectedRoute>,
      children: [
        { path: 'user/:userId', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN]}><User/></AppProtectedRoute> },
        { path: 'users', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN]}><Users/></AppProtectedRoute> },
        { path: 'langs', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN]}><Langs /></AppProtectedRoute> },
        { path: 'langs/:langId', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN]}><Lang /></AppProtectedRoute> },
        { path: 'news/:newsId', element: <AppProtectedRoute accessForRoles={[...APP_ADMIN_ROLES, USER_ROLE_NEWS_EDITOR]}><NewsDetail/></AppProtectedRoute> },
        { path: 'news', element: <AppProtectedRoute accessForRoles={[...APP_ADMIN_ROLES, USER_ROLE_NEWS_EDITOR]}><News/></AppProtectedRoute> },
        { path: 'notifications', element: <AppProtectedRoute accessForRoles={[...APP_ADMIN_ROLES, USER_ROLE_NEWS_EDITOR]}><Notifications /></AppProtectedRoute> },
        { path: 'selectors', children: [
            { path: 'item/:selectorId', element: <AppProtectedRoute accessForRoles={APP_ADMIN_ROLES}><Selector/></AppProtectedRoute> },
            { path: ':base64Search', element: <AppProtectedRoute accessForRoles={APP_ADMIN_ROLES}><Selectors/></AppProtectedRoute> },
            { index: true, element: <AppProtectedRoute accessForRoles={APP_ADMIN_ROLES}><Selectors/></AppProtectedRoute> },
          ] },
        { path: 'car-brands', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]} requiredPart={APP_PART_CARS}><CarBrands /></AppProtectedRoute> },
        { path: 'car-brands/:brandId', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]} requiredPart={APP_PART_CARS}><CarBrand /></AppProtectedRoute> },
        { path: 'cars', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]} requiredPart={APP_PART_CARS}><Cars /></AppProtectedRoute> },
        { path: 'cars/item/:carId', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]} requiredPart={APP_PART_CARS}><Car /></AppProtectedRoute> },
        { path: 'brands', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]}><Brands /></AppProtectedRoute> },
        { path: 'brands/:brandId', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN, USER_ROLE_APP_ADMIN]}><Brand /></AppProtectedRoute> },
        { path: 'regions', element: <AppProtectedRoute><Regions /></AppProtectedRoute> },
        { path: 'regions/:regionId', element: <AppProtectedRoute accessForRoles={[USER_ROLE_SUPER_ADMIN]}><Region /></AppProtectedRoute> },
        { path: 'feedbacks', element: <AppProtectedRoute accessForRoles={APP_ADMIN_ROLES}><Feedbacks /></AppProtectedRoute> },
        { path: 'feedbacks/:feedbackId', element: <AppProtectedRoute accessForRoles={APP_ADMIN_ROLES}><Feedback /></AppProtectedRoute> },
        { path: 'app', element: <AppProtectedRoute><DashboardApp/></AppProtectedRoute> },
        { path: '404', element: <NotFound/> },
        { path: 'forbidden', element: <Forbidden/> },
        { path: '*', element: <Navigate to="/dashboard/404" replace={true} /> },
      ],
    },
    {
      path: '/',
      element: <Outlet />,
      children: [
        { path: '/sign-in', element: <SignIn/> },
        { path: '/select-app', element: <SelectApp/> },
        { path: '*', element: <Navigate to="/dashboard/404" replace={true} /> },
      ],
    },
    { index: true, element: <DefaultPageNavigate />},
  ])
}
