import { DataGrid }                                            from '@mui/x-data-grid'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext }                                          from '../../App'
import { Card }                                                from '@mui/material'
import { styled }                                              from '@mui/material/styles'

const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

const BaseTable = React.forwardRef((props, ref) => {

  let { loadCallback, columns, query = '', initialSortModel = [{ field: 'id', sort: 'desc' }], initialPage = 0, forceReloadCounter = 0, initialPageSize = 100, ...otherProps } = props

  if (!loadCallback) {
    throw new Error('Property loadCallback not implemented')
  }

  const { currentApp } = useContext(AppContext)
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(initialPage)
  const [pageSize, setPageSize] = useState(initialPageSize)
  const [loading, setLoading] = useState(false)
  const [sortModel, setSortModel] = useState(initialSortModel)

  const onPageChange = useCallback((page) => {
    setPage(page)
  }, []);
  const onPageSizeChange = useCallback((pageSize) => {
    setPageSize(pageSize)
  }, []);

  useEffect(() => {
    setPage(0)
  }, [query])

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      let orderBy = sortModel && sortModel[0] ? [[ sortModel[0].field, sortModel[0].sort ]] : []
      const { data, total } = await props.loadCallback(query, page, pageSize, orderBy)
      if (!active) {
        return;
      }

      setRows(data);
      setRowCount(total)
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, query, sortModel, currentApp, forceReloadCounter]);

  React.useImperativeHandle(ref, () => ({
    getData: () => {
      return rows
    }
  }))

  return (
    <>
      <Card>
        <StyledDataGrid
          autoHeight={true}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          page={page}
          pageSize={pageSize}
          paginationMode={'server'}
          sortingMode={'server'}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          loading={loading}
          rowHeight={32}
          disableSelectionOnClick={true}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => { setSortModel(newSortModel) }}
          {...otherProps}
        />
      </Card>
    </>
  );
})

export default BaseTable