import Page                                     from '../components/Page'
import { Button, Chip, Container, Link, Stack } from '@mui/material'
import Typography                               from '@mui/material/Typography'
import { Link as RouterLink }                   from 'react-router-dom'
import Iconify                                  from '../components/Iconify'
import BaseTable                                from '../components/base/BaseTable'
import React                                    from 'react'
import { t }                                    from 'i18next'
import { useApi }                               from '../utils/api'

export default function Langs() {

  const api = useApi();
  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, filterable: false },
    { field: 'name', headerName: t('Name'), flex: 2, filterable: false, renderCell: (cell) => {
        return (
          <Stack direction={'row'} gap={2}>
            <Link
              component={RouterLink}
              size="small"
              to={`/dashboard/langs/${cell.id}`}
            >
              {`${cell.row.name}`}
            </Link>
            {cell.row.isDefault && <Chip size={'small'} label={t('Default')} color={'primary'} variant={'outlined'} />}
          </Stack>
        )
      }, },
    { field: 'code', headerName: t('Code'), flex: 2, filterable: false, renderCell: (cell) => {
        return (
          <Typography sx={{ ml: 2, color: 'text.secondary' }} variant="caption">
            {cell.row.code}
          </Typography>
      )
      }, },
  ];

  return (<Page title={t('Langs')}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>{t('Langs')}</Typography>
        <Button variant="contained" component={RouterLink} to="/dashboard/langs/create" startIcon={<Iconify icon="eva:plus-fill" />}>
          {t('New lang')}
        </Button>
      </Stack>

      <BaseTable
        columns={columns}
        loadCallback={async (query, page, pageSize, orderBy) => {
          try {
            return await api.lang.list(query, page, pageSize, [], orderBy)
          } catch (e) {
            console.log('Failed to load langs', e)
            return []
          }
        }}
      />
    </Container>
  </Page>);

}