import { Autocomplete, Box, Checkbox, CircularProgress, LinearProgress, TextField } from '@mui/material'
import PropTypes                                                                    from 'prop-types'
import CheckBoxOutlineBlankIcon                                                     from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon                                                                 from '@mui/icons-material/CheckBox'
import { t }                                                                        from 'i18next'
import useRegions                                                                   from '../hooks/useRegions'
import useApps                                                                      from '../hooks/useApps'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext }                             from '../App'

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

export function RegionsCheckboxes (props) {

  const { selectedRegionCodes, onSelectedRegionCodesChange, handleProperty = 'code', multiple } = props

  const { user } = useContext(AppContext)
  const [allRegions] = useRegions()

  const regions = user.regionIds?.length > 0 ? allRegions?.filter(reg => user.regionIds.indexOf(reg.id) > -1) : allRegions

  const getSelectedRegions = () => {
    return regions?.filter(it => selectedRegionCodes.indexOf(it[handleProperty]) > -1) ?? []
  }

  return (
    <Autocomplete
      multiple={multiple === undefined || multiple === true}
      id="regions-tags"
      options={regions ?? []}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      onChange={(_, values) => {
        onSelectedRegionCodesChange(values.map(it => it[handleProperty]))
      }}
      value={getSelectedRegions()}
      renderOption={(props, option, data) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={data.selected}
            />
            {option.name}
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} fullWidth variant={'standard'} label={t('Regions')} placeholder={t('Regions')}/>
        )
      }}
    />
  )
}

RegionsCheckboxes.propTypes = {
  selectedRegionCodes: PropTypes.array.isRequired,
  onSelectedRegionCodesChange: PropTypes.func.isRequired,
}


export function AppsCheckboxes (props) {

  const { selectedAppIds, onAppIdsChange } = props

  const [apps] = useApps()

  const getSelectedApps = () => {
    return apps.filter(it => selectedAppIds.indexOf(it.id) > -1)
  }

  if (!apps) {
    return (<Box><CircularProgress /></Box>)
  }

  return (
    <Autocomplete
      multiple
      id="regions-tags"
      options={apps}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      onChange={(_, values) => {
        onAppIdsChange(values.map(it => it.id))
      }}
      value={getSelectedApps()}
      renderOption={(props, option, data) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={data.selected}
            />
            {option.name}
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} fullWidth variant={'standard'} label={t('Apps')} placeholder={t('Apps')}/>
        )
      }}
    />
  )
}

AppsCheckboxes.propTypes = {
  selectedAppIds: PropTypes.array.isRequired,
  onAppIdsChange: PropTypes.func.isRequired,
}

export function BrandAutocomplete (props) {

  const { selectedBrandId, onSelectedBrandIdChanged, brands } = props

  const getSelectedBrands = () => brands?.filter(it => selectedBrandId === it.id)?.pop() ?? null

  if (!brands) {
    return (<Box sx={{ height: '48px' }}><LinearProgress /></Box>)
  }

  return (
    <Autocomplete
      id="brand-tags"
      options={brands}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.name ?? ''}
      getOptionDisabled={(brand) => brand.active === false}
      onChange={(_, values) => {
        onSelectedBrandIdChanged(values?.id)
      }}
      value={getSelectedBrands()}
      renderOption={(props, option, data) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={data.selected}
            />
            {option.name}
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} fullWidth variant={'standard'} label={t('Brand')} placeholder={t('Brand')}/>
        )
      }}
    />
  )
}

BrandAutocomplete.propTypes = {
  selectedBrandId: PropTypes.number,
  onSelectedBrandIdChanged: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
}

export function TagsAutocomplete(props) {

  const { values, onValuesChanged } = props

  const [_values, setValues] = useState(values)

  useEffect(() => {
    onValuesChanged(_values)
  }, [_values])

  return (
    <Autocomplete options={_values} value={_values}
                  selectOnFocus clearOnBlur multiple freeSolo={true} open={false}
                  renderOption={(props, option, data) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={data.selected}
                        />
                        {option}
                      </li>
                    )
                  }}
                  onChange={(event, newValues, reason) => {
                    event.preventDefault()
                    setValues(newValues)
                  }}
                  renderInput={(params) => {
                    return (<TextField {...params} fullWidth variant={'standard'} label={t('Search')} placeholder={t('Search')}/>)
                  }}/>
  )
}

TagsAutocomplete.propTypes = {
  values: PropTypes.array.isRequired,
  onValuesChanged: PropTypes.func.isRequired,
}


export function TextCheckboxes (props) {

  const { id, title, options, values, onValuesChanged } = props

  return (
    <Autocomplete
      multiple={true}
      id={id}
      options={options ?? []}
      disableCloseOnSelect
      getOptionLabel={(option) => option ?? ''}
      onChange={(_, values) => {
        onValuesChanged(values)
      }}
      value={values}
      renderOption={(props, option, data) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={data.selected}
            />
            {option}
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} fullWidth variant={'standard'} label={title} placeholder={title}/>
        )
      }}
    />
  )
}

TextCheckboxes.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onValuesChanged: PropTypes.func.isRequired,
}