import React, { useContext, useEffect, useState } from 'react'
import { AppContext }                             from '../App'
import {
  Box, Button, Card, Container,
  Divider, Grid, List, Stack,
}                                      from '@mui/material'
import { t }                           from 'i18next'
import { ApplicationItem }             from '../layouts/dashboard/AppsPopover'
import Typography                      from '@mui/material/Typography'
import { useNavigate }                 from 'react-router-dom'
import useApps                         from '../hooks/useApps'
import { getDefaultURL }               from '../utils/consts'

export default function SelectApp () {

  const navigate = useNavigate()
  const { currentApp, setCurrentApp, user } = useContext(AppContext)
  const [availableApps, reloadAvailableApps] = useApps()
  const [selectedApp, setSelectedApp] = useState(null)

  useEffect(() => {
    reloadAvailableApps()
  }, [user])

  return (
    <Container>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent={'center'} p={5}>
        <Grid item xs={12} md={6}>
          <Card>
            <Box sx={{ py: 2, px: 2.5 }}>
              <Typography variant="subtitle1">{t('Choose your application')}</Typography>
            </Box>
            <List disablePadding>
              <Divider sx={{ borderStyle: 'dashed' }}/>
              {availableApps?.map((app) => (
                <ApplicationItem key={app.id} application={app} currentApp={selectedApp} onClick={() => {
                  setSelectedApp(app)
                }}/>
              ))}
            </List>
            <Stack direction="row" alignItems="center" justifyContent="end" m={3}>
              <Button variant="contained" disabled={selectedApp == null} onClick={(e) => {
                setCurrentApp(selectedApp)
                navigate(getDefaultURL(user.role))
              }}>
                {t('Confirm')}
              </Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}