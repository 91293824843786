import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
}                      from '@mui/material'
import FileUploadIcon
                       from '@mui/icons-material/FileUpload'
import { styled }      from '@mui/material/styles'
import IconButton      from '@mui/material/IconButton'
import DeleteIcon      from '@mui/icons-material/Delete'
import React           from 'react'
import PropTypes       from 'prop-types'
import { useApi }      from '../utils/api'
import { useSnackbar } from 'notistack'
import { t }           from 'i18next'

const Input = styled('input')({
  display: 'none',
})

const PreviewItem = ({ item, onDelete }) => {

  return (<ImageListItem key={item.url}>
      <img
        src={`${item.url}`}
        srcSet={`${item.url}`}
        alt={item.nameBase}
        loading="lazy"
      />
      <ImageListItemBar
        title={item.title}
        actionIcon={
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
            aria-label={`info about ${item.nameBase}`}
            onClick={(e) => { onDelete(item) }}
          >
            <DeleteIcon/>
          </IconButton>
        }
      />
    </ImageListItem>)
}

PreviewItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
}

const PreviewList = ({ data, title, onDelete, cols = 2 }) => {
  return (
    <ImageList sx={{ width: '100%' }} cols={cols}>
      {title && (<ImageListItem key="Subheader" cols={cols}>
        <ListSubheader component="div" sx={{ lineHeight: '1rem', pl: 0 }}>{title}</ListSubheader>
      </ImageListItem>)
      }
      {data.map((item) => (<PreviewItem key={`preview-${item.id}`} item={item} onDelete={onDelete} />))}
    </ImageList>
  )
}

PreviewList.propTypes = {
  title: PropTypes.string,
  cols: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
}

export default function ImageFileUpload (props) {

  const { cols, maxCount, title, multiple, data, onDataChanged } = props
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const onFilesChanged = (event) => {
    if (event.target.files.length === 0) {
      return
    }
    const formData = new FormData()

    for (let i = 0; i < event.target.files.length; i++) {
      formData.append(`file-${i}`, event.target.files[i], event.target.files[i].name)
    }

    api.media.upload(formData).then(response => {
      onDataChanged([...data, ...response.data])
    }).catch(error => {
      enqueueSnackbar(t(`Failed to upload file ${error.error}`), {
        variant: 'error'
      })
    })
  }

  return (
    <>
      <PreviewList data={data} cols={cols ?? 1} title={title} onDelete={(item) => {
        onDataChanged(data.filter(it => it.id !== item.id))
      }}/>
      {(data.length === 0 || multiple && (!maxCount || data.length < maxCount)) && (<label style={{ marginTop: '16px' }}>
        <Input accept="image/*"
               type="file"
               multiple={multiple ?? false}
               onChange={onFilesChanged}/>
        <Button variant={'text'} fullWidth component="span">
          <FileUploadIcon />
        </Button>
      </label>)}
    </>
  )
}

ImageFileUpload.propTypes = {
  cols: PropTypes.number,
  maxCount: PropTypes.number,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  data: PropTypes.array.isRequired,
  onDataChanged: PropTypes.func.isRequired,
}