import {
  Box, Button, Card,
  Container,
  Grid,
  Stack,
} from '@mui/material'
import Typography            from '@mui/material/Typography'
import { t }                 from 'i18next'
import { AlertSimpleDialog } from '../components/base/BaseDialog'
import React, { useState }   from 'react'
import PropTypes             from 'prop-types'

export default function BaseForm(props) {

  const { children, id, formTitle, values, nameProp = 'name', header, onSubmit,  onDelete, gridContainerProps = {} } = props

  const [shownDeleteDialog, setShownDeleteDialog] = useState(false);

  const handleCloseDeleteDialog = (confirmed) => {
    setShownDeleteDialog(false)
    if (confirmed) {
      onDelete(id)
    }
  }

  return (<>
    <Container>
      {formTitle && <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4" gutterBottom>{formTitle}</Typography>
      </Stack>}
      {!!header && header}
      <Box component={'form'} onSubmit={onSubmit}>
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2} {...gridContainerProps}>
            {children}
          </Grid>
        </Card>
        <Box sx={{ mt: 2, px: 1 }}>
          <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
          { !!id && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }} onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>) }
        </Box>
      </Box>
    </Container>
    <AlertSimpleDialog
      title={t('Confirm delete')}
      message={t('delete-confirm-msg', { name: values[nameProp] })}
      open={shownDeleteDialog}
      onClose={handleCloseDeleteDialog}
    />
  </>)
}


BaseForm.propTypes = {
  id: PropTypes.number.isRequired,
  formTitle: PropTypes.string,
  nameProp: PropTypes.string,
  values: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  header: PropTypes.element,
}