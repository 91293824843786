import React, { useEffect, Suspense }            from 'react'
import { SnackbarProvider }                      from 'notistack'
import { useApi, useCurrentApp, useCurrentUser } from './utils/api'
import Router                                    from './routes'
import { useNavigate }                           from 'react-router-dom'
import ThemeProvider                             from './theme'
import ScrollToTop                               from './components/ScrollToTop'
import { setSelectorSearch }                     from './utils/storage'

export const AppContext = React.createContext({
  currentApp: null,
  user: null,
});

function App() {

  const navigate = useNavigate();
  const api = useApi();
  const [user, setUser] = useCurrentUser();
  const [currentApp, setCurrentApp] = useCurrentApp();

  useEffect(() => {
    if (user !== null) {
      api.user.validateHash(user.accessToken)
        .then(validateUser => {
          setUser({
            ...validateUser,
            accessToken: user.accessToken
          })
        })
        .catch(err => {
          setUser(null)
          setCurrentApp(null)
          navigate('/sign-in')
        })
    }
  }, [])

  useEffect(() => {
    if (user === null) {
      setSelectorSearch(null)
    }
  }, [user])

  const setCurrentAppImpl = (app) => {
    setCurrentApp(app)
    setSelectorSearch(null)
  }

  return (<Suspense fallback="loading">
    <ThemeProvider>
      <AppContext.Provider value={{ currentApp: currentApp, setCurrentApp: setCurrentAppImpl, user: user, setUser: setUser }}>
        <SnackbarProvider>
          <ScrollToTop />
          <Router />
        </SnackbarProvider>
      </AppContext.Provider>
    </ThemeProvider>
  </Suspense>)
}

export default App;