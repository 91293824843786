import PropTypes                                   from 'prop-types'
import { useState, useRef, useContext, useEffect } from 'react'
// @mui
import {
  List,
  Badge,
  Avatar,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton, ListSubheader,
}                                       from '@mui/material'
// utils
import Iconify                          from '../../components/Iconify'
import Scrollbar                        from '../../components/Scrollbar'
import MenuPopover                      from '../../components/MenuPopover'
import { t }                            from 'i18next'
import { AppContext }                   from '../../App'
import useApps                          from '../../hooks/useApps'

// ----------------------------------------------------------------------

export function AppsPopover() {
  const anchorRef = useRef(null);

  const { currentApp, setCurrentApp } = useContext(AppContext);
  const [availableApps] = useApps();
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 44,
          height: 44,
        }}
      >
        {currentApp == null && <Badge badgeContent={'!'} color="error">
          <Iconify icon="eva:grid-outline" width={20} height={20} />
        </Badge>}
        {currentApp != null && <Avatar src={currentApp.iconUrl} width={20} height={20} />}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    {t('Choose your application')}
                  </ListSubheader>
                }>
            <Divider sx={{ borderStyle: 'dashed' }} />
            {availableApps?.map((app) => (
              <ApplicationItem key={app.id} application={app} currentApp={currentApp} onClick={() => {
                setCurrentApp(app)
                setOpen(false)
              }} />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

ApplicationItem.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    iconUrl: PropTypes.string,
  }),
};

export function ApplicationItem({ application, currentApp, onClick }) {

  const title = (
    <Typography variant="subtitle2">
      {application.name}
    </Typography>
  );

  const subtitle = !!application.description && (<Typography
    variant="caption"
    sx={{
      mt: 0.5,
      display: 'flex',
      alignItems: 'center',
      color: 'text.disabled',
    }}
  >
    {application.description}
  </Typography>);

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(application.id === currentApp?.id && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }} src={application.iconUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={subtitle}
      />
    </ListItemButton>
  );
}