import React, { useContext }                           from 'react'
import { Button, Card, Container, Grid, Paper, Stack } from '@mui/material'
import { useTranslation }                              from 'react-i18next'
import Page                                            from '../components/Page'
import { LastFoilsUpdates, LastSelectorUpdates }       from '../sections/dashboard/ItemUpdates'
import Typography                                      from '@mui/material/Typography'
import { t }                                           from 'i18next'
import { Link as RouterLink }                          from 'react-router-dom'
import Iconify                                         from '../components/Iconify'
import { AppContext }                                  from '../App'

export default function DashboardApp () {

  const { t } = useTranslation()
  const { currentApp } = useContext(AppContext)

  return (<Page title={t('Dashboard')}>
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('Dashboard')}
        &nbsp;
        <Typography
          component="span"
          variant="body1"
          sx={{
            color: 'text.disabled',
          }}
        >
          {currentApp?.name}
        </Typography>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <LastSelectorUpdates />
        </Grid>
        <Grid item xs={12} md={6}>
          <LastFoilsUpdates />
        </Grid>
      </Grid>
    </Container>
  </Page>)
}