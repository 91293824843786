import { useNavigate, useParams }                            from 'react-router-dom'
import React, { useEffect, useState }                        from 'react'
import { useApi }                                            from '../utils/api'
import { useSnackbar }                                       from 'notistack'
import {
  Box, Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel, MenuItem,
  Radio,
  RadioGroup, Select,
  Stack,
  TextField
}                                                            from '@mui/material'
import BaseLangSelect                                        from '../components/base/BaseLangSelect'
import { t }                                                 from 'i18next'
import Loader                                                from '../components/Loader'
import BaseForm                                              from './BaseForm'
import Page                                                  from '../components/Page'
import useCarBrands                                          from '../hooks/useCarBrands'
import { BrandAutocomplete }                                                              from '../components/Checkboxes'
import {
  CAR_THUMBNAIL_BIG,
  CAR_THUMBNAIL_SMALL,
  CAR_TYPE_SEDAN,
  CAR_TYPES,
  STATE_DELETED,
  STATE_DRAFT, STATE_PUBLISHED
} from '../utils/consts'
import Typography                                                                         from '@mui/material/Typography'
import DocumentFileUpload                                    from '../components/DocumentFileUpload'
import ImageFileUpload                                       from '../components/ImageFileUpload'

const defaultValues = {
  carBrandId: 0,
  series: '',
  modelYear: '',
  type: CAR_TYPE_SEDAN,
  length: '',
  state: STATE_DRAFT,
  about: {},
  howTo: {},
  inAppIds: {},
}

export default function Car() {

  const { carId } = useParams()
  const navigate = useNavigate()
  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()

  const [lang, setLang] = useState()
  const [loading, setLoading] = useState(false)

  const [carBrands] = useCarBrands()
  const [values, setValues] = useState(defaultValues)
  const [smallThumbnails, setSmallThumbnails] = useState([])
  const [bigThumbnails, setBigThumbnails] = useState([])

  useEffect(() => {
    (async () => {
      setLoading(true)
      if (!isNaN(carId)) {
        let car = await api.car.detail(carId, ['carBrand', 'icon', 'model', 'thumbnails'])
        let smallThumbs = car?.thumbnails?.filter(thumb => thumb.type === CAR_THUMBNAIL_SMALL).map(thumb => thumb.media)
        let bigThumbs = car?.thumbnails?.filter(thumb => thumb.type === CAR_THUMBNAIL_BIG).map(thumb => thumb.media)
        setSmallThumbnails(() => smallThumbs)
        setBigThumbnails(() => bigThumbs)
        setValues({
          ...defaultValues,
          ...car,
        })
      }
      setLoading(false)
    })()
  }, [carId])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const handleTranslatedValue = (prop) => (event) => {
    let property = values[prop]
    property[lang] = event.target.value
    setValues({ ...values, [prop]: property });
  }

  const translatedValue = (property) => {
    return (values[property] ?? {})[lang] ?? ''
  }

  const carFormHeader = (
    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
      <Typography variant="h4" gutterBottom>{t('Car')}</Typography>
      <Box flex={1}/>
      <BaseLangSelect onLangChanged={(lang) => {
        setLang(lang?.code)
      }}/>
    </Stack>
  )

  return (<Page title={t('Selector')}>
    <Loader loading={loading}>
      <BaseForm
        id={isNaN(carId) ? 0 : parseInt(carId)}
        header={carFormHeader}
        onDelete={async (id) => {
          try {
            await api.car.delete(id)
            navigate(`/dashboard/cars`)
            enqueueSnackbar(t('Car deleted'), {
              variant: 'success'
            })
          } catch (e) {
            console.log(e)
            enqueueSnackbar(t('Failed to delete car'), {
              variant: 'error'
            })
          }
        }}
        onSubmit={async (e) => {
          e.preventDefault()
          let requestValues = {
            ...values,
          }
          delete requestValues.icon
          delete requestValues.model
          let thumbnails = bigThumbnails.map(thumb => ({
            mediaId: thumb.id,
            type: CAR_THUMBNAIL_BIG,
          }))
          thumbnails.concat(smallThumbnails.map(thumb => ({
            mediaId: thumb.id,
            type: CAR_THUMBNAIL_SMALL,
          })))
          requestValues.thumbnails = thumbnails
          try {
            let car = await api.car.save(requestValues)
            navigate(`/dashboard/cars/item/${car.id}`)
            enqueueSnackbar(t('Car saved'), {
              variant: 'success'
            })
          } catch (e) {
            enqueueSnackbar(t('Failed to save car'), {
              variant: 'error'
            })
          }
        }}
        values={values}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{t('General')}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <BrandAutocomplete
            brands={carBrands ?? []}
            selectedBrandId={values?.carBrandId ?? null}
            onSelectedBrandIdChanged={(brandId) => {
              handleChange('carBrandId')({ target: { value: brandId } })
            }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="field-name"
            label={t('Series')}
            fullWidth
            value={values.series}
            onChange={handleChange('series')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="field-year"
            label={t('Model year')}
            fullWidth
            value={values.modelYear}
            onChange={handleChange('modelYear')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="select-car-type">{t('Type')}</InputLabel>
            <Select
              labelId="select-car-type"
              id="select-car-type"
              value={values.type}
              onChange={handleChange('type')}
            >
              {CAR_TYPES.map(carType => (
                <MenuItem value={carType}>{t(`carType_${carType}`)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="field-length"
            label={t('Length')}
            fullWidth
            type={'number'}
            value={values.length}
            onChange={handleChange('length')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="label-state">{t('State')}</FormLabel>
            <RadioGroup row aria-labelledby="label-state" name="state" value={values.state} onChange={handleChange('state')}>
              <FormControlLabel value={STATE_DRAFT} control={<Radio />} label={t('Draft')} />
              <FormControlLabel value={STATE_PUBLISHED} control={<Radio />} label={t('Published')} />
              <FormControlLabel value={STATE_DELETED} control={<Radio />} label={t('Deleted')} />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="field-about"
            label={t('About')}
            multiline
            fullWidth
            maxRows={4}
            value={translatedValue('about')}
            onChange={handleTranslatedValue('about')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-how_to"
            label={t('How to')}
            multiline
            fullWidth
            maxRows={4}
            value={translatedValue('howTo')}
            onChange={handleTranslatedValue('howTo')}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{t('In app ids')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-ios-normal"
            label={t('iOS normal in-app')}
            fullWidth
            value={values.inAppIds?.IosSelectorNormalPrice ?? ''}
            onChange={(e) => {
              handleChange('inAppIds')({ target: { value: ({
                    ...values.inAppIds,
                    IosSelectorNormalPrice: e.target.value
                  }) } })
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="field-ios-premium"
            label={t('iOS premium account in-app')}
            fullWidth
            value={values.inAppIds?.IosSelectorPremiumAccountPrice ?? ''}
            onChange={(e) => {
              handleChange('inAppIds')({ target: { value: ({
                    ...values.inAppIds,
                    IosSelectorPremiumAccountPrice: e.target.value
                  }) } })
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{t('Media files')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DocumentFileUpload title={t('3D Model')}
                              accept={'*'}
                              data={values?.model ? [values.model] : []}
                              onDataChanged={(data) => {
                                let media = data.length === 1 ? data[0] : null
                                setValues((val) => ({
                                  ...val,
                                  model: media ?? null,
                                  modelMediaId: media?.id ?? null,
                                }));
                              }} />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl variant="standard" fullWidth>
            <ImageFileUpload
              multiple={false}
              title={t('Icon')}
              data={values.icon ? [values.icon] : []}
              onDataChanged={(newIcon) => {
                let icon = newIcon?.pop()
                setValues((val) => ({
                  ...val,
                  icon: icon ?? null,
                  iconMediaId: icon?.id ?? null,
                }));
              }} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true}>{t('Big thumbnails')}</InputLabel>
            <ImageFileUpload
              cols={5}
              multiple={true}
              title={t('Big thumbnails')}
              data={bigThumbnails}
              onDataChanged={(thumbnails) => {
                setBigThumbnails(thumbnails)
              }} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true}>{t('Small thumbnails')}</InputLabel>
            <ImageFileUpload
              cols={5}
              multiple={true}
              title={t('Small thumbnails')}
              data={smallThumbnails}
              onDataChanged={(thumbnails) => {
                setSmallThumbnails(thumbnails)
              }} />
          </FormControl>
        </Grid>
      </BaseForm>
    </Loader>
  </Page>)
}