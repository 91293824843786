import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material'
import Foil                                               from '../../pages/Foil'
import PropTypes                                          from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Typography
                                                          from '@mui/material/Typography'
import { t }                                              from 'i18next'
import Iconify
                                                          from '../../components/Iconify'
import { useApi }                                         from '../../utils/api'
import { useSnackbar }                                    from 'notistack'
import { AlertSimpleDialog }                              from '../../components/base/BaseDialog'
import { AppContext }                                     from '../../App'
import { USER_ROLE_APP_EDITOR }                           from '../../utils/consts'

export default function FoilsDialog(props) {

  const { handleClose, selectorId, foilIds, onDelete, open, selectedIndex, setSelectedIndex } = props;

  const api = useApi()
  const refFoilForm = useRef()
  const { user } = useContext(AppContext)
  const [foilId, setFoilId] = useState(null)
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [forceReload, setForceReload] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [dialogTitle, setDialogTitle] = useState(t('Foil'))

  useEffect(() => {
    setFoilId(() => selectedIndex === null ? null : foilIds[selectedIndex])
  }, [selectedIndex, forceReload])


  const handleSave = (action) => async (e) => {
    let values = refFoilForm.current.getFormValues()
    try {
      let foil = await api.foil.save(selectorId, values)
      enqueueSnackbar(t('Foil saved'), {
        variant: 'success'
      })
      if (action === 'close') {
        handleClose()
      } else if (action === 'next') {
        goToNextIndex()
      }
    } catch (e) {
      enqueueSnackbar(t('Failed to save foil'), {
        variant: 'error'
      })
    }
  }

  const handleCloseDeleteDialog = (confirmed) => {
    setShownDeleteDialog(false)
    if (confirmed) {
      handleDelete()
    }
  }

  const handleDelete = async (e) => {
    if (!foilId) {
      return;
    }
    try {
      await api.foil.delete(foilId)
      enqueueSnackbar(t('Foil deleted'), {
        variant: 'success'
      })
      if (onDelete(foilId)) {
        setForceReload(prev => prev + 1)
      }
    } catch (e) {
      enqueueSnackbar(t('Failed to delete foil'), {
        variant: 'error'
      })
    }
  }

  const goToNextIndex = () => {
    setSelectedIndex(prevIndex => {
      return Math.min(prevIndex + 1, foilIds.length - 1)
    })
  }
  const goToPrevIndex = () => {
    setSelectedIndex(prevIndex => {
      return Math.max(prevIndex - 1, 0)
    })
  }

  const isEditing = foilId !== null && foilIds.length > 0

  return (<div>
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Stack direction={'row'} spacing={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h4">{dialogTitle}</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {isEditing && <>
              <IconButton onClick={goToPrevIndex}>
                <Iconify icon={'eva:chevron-left-fill'} />
              </IconButton>
              <Typography variant={'caption'} sx={{ color: 'text.secondary' }}>{t('format-of', { current: selectedIndex + 1, total: foilIds.length })}</Typography>
              <IconButton onClick={goToNextIndex}>
                <Iconify icon={'eva:chevron-right-fill'} />
              </IconButton>
            </>}
            <IconButton onClick={e => {
              handleClose()
            }}>
              <Iconify icon={'eva:close-fill'} />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Foil ref={refFoilForm}
              selectorId={selectorId}
              foilId={foilId}
              showColorParams={user?.role !== USER_ROLE_APP_EDITOR}
              onFoilLoaded={(foil) => {
                setDialogTitle(foil.id ? [foil.colorCode, foil.name[foil.defaultLanguage]].filter(it => !!it).join(' - ') : t('Foil'))
              }}
        />
      </DialogContent>
      <DialogActions>
        <Box display={'flex'} justifyContent={isEditing ? 'space-between' : 'end'} flex={1} px={3}>
          {isEditing && <Button color={'error'} onClick={() => {
            setShownDeleteDialog(true)
          }}>{t('Delete')}</Button>}
          <div>
            {isEditing && <Button onClick={handleSave(null)}>{t('Save')}</Button>}
            <Button onClick={handleSave('close')}>{t('Save & Close')}</Button>
            {isEditing && <Button onClick={handleSave('next')}>{t('Save & Next')}</Button>}
          </div>
        </Box>
      </DialogActions>
    </Dialog>
    <AlertSimpleDialog
      title={t('Confirm delete')}
      message={t('delete-confirm-msg', { name: `Foil  #${foilId}` })}
      open={shownDeleteDialog}
      onClose={handleCloseDeleteDialog}
    />
  </div>)
}

FoilsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  selectorId: PropTypes.number,
  foilIds: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}