import { useApi }                                       from '../utils/api'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext }                                   from '../App'

let regions
export default function useRegions() {
  const { user } = useContext(AppContext)
  const api = useApi()
  const [local, setLocal] = useState(regions)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    regions = undefined
    setReload(reload => reload + 1)
  }, [])

  useEffect(() => {
    (async function() {
      regions = (await api.region.list()).data.sort((a, b) => a.name.localeCompare(b.name))
      setLocal(regions)
    })();
  }, [reload, user])

  return [local, reloadFnc]
}