import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl, FormControlLabel,
  Grid,
  Input,
  InputLabel, LinearProgress, MenuItem,
  Select, Stack,
  Switch, TextField,
}                                     from '@mui/material'
import { useTranslation }             from 'react-i18next'
import { useNavigate, useParams }     from 'react-router-dom'
import { useApi }                     from '../utils/api'
import { useSnackbar }                from 'notistack'
import { RegionsCheckboxes }          from '../components/Checkboxes'
import {
  dateFromInputStringToSQL,
  dateToInputString,
  INPUT_DATE_TIME_FORMAT,
  parseUTC,
  SQL_DATE_TIME_FORMAT
}                                     from '../utils/dateutils'
import ImageFileUpload                from '../components/ImageFileUpload'
import Page                           from '../components/Page'
import BaseForm                       from './BaseForm'
import { Editor }                     from '@tinymce/tinymce-react'
import { TINY_MCE_API_KEY }           from '../utils/consts'
import { DateTime, Duration }         from 'luxon'

const PUBLISHED_TO_WEEK = 'month_week'
const PUBLISHED_TO_MONTH_1 = 'month_1'
const PUBLISHED_TO_MONTH_3 = 'month_3'
const PUBLISHED_TO_MONTH_6 = 'month_6'
const PUBLISHED_TO_FOREVER = 'month_forever'

const convertDateToConst = (from, to) => {
  if (!from || !to) {
    return PUBLISHED_TO_FOREVER
  }
  let fTime = parseUTC(from)
  let tTime = parseUTC(to)
  let diff = tTime.diff(fTime, ['months', 'weeks'])
  if (diff.weeks === 1 && diff.months === 0) {
    return PUBLISHED_TO_WEEK
  } else if (diff.months === 1) {
    return PUBLISHED_TO_MONTH_1
  } else if (diff.months === 3) {
    return PUBLISHED_TO_MONTH_3
  } else if (diff.months === 6) {
    return PUBLISHED_TO_MONTH_6
  }
  return PUBLISHED_TO_FOREVER
}

const convertStrToDate = (from, toId) => {
  let fTime = DateTime.fromFormat(from, INPUT_DATE_TIME_FORMAT).toUTC()
  switch (toId) {
    case PUBLISHED_TO_WEEK:
      return fTime.plus(Duration.fromObject({ weeks: 1 }))
    case PUBLISHED_TO_MONTH_1:
      return fTime.plus(Duration.fromObject({ months: 1 }))
    case PUBLISHED_TO_MONTH_3:
      return fTime.plus(Duration.fromObject({ months: 3 }))
    case PUBLISHED_TO_MONTH_6:
      return fTime.plus(Duration.fromObject({ months: 6 }))
    case PUBLISHED_TO_FOREVER:
    default:
      return null
  }
}

export default function NewsDetail() {

  const api = useApi()
  const navigate = useNavigate()
  const { newsId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [selectors, setSelectors] = useState([])
  const [screenInitialized, setScreenInitialized] = useState(false)
  const [values, setValues] = useState({
    title: '',
    content: '',
    type: '1',
    sendPushNotification: false,
    linkWebsite: '',
    linkVideo: '',
    thumbnailMediaId: null,
    media: [],
    regionIds: [],
    selectorId: '',
    publishedTo: PUBLISHED_TO_FOREVER,
  })

  useEffect(() => {
    (async () => {
      let { data } = await api.selector.list(null, null, 0, null, ['brand'], [['name', 'ASC']])
      setSelectors(data)
      if (!isNaN(newsId)) {
        let news = await api.news.detail(newsId, ['media', 'thumbnail'])
        setValues({
          ...news,
          publishedFrom: !!news.publishedFrom ? dateToInputString(news.publishedFrom) : null,
          publishedTo: convertDateToConst(news.publishedFrom, news.publishedTo),
          eventFrom: !!news.eventFrom ? dateToInputString(news.eventFrom) : null,
          eventTo: !!news.eventTo ? dateToInputString(news.eventTo) : null,
          selectorId: news?.selectorId ?? '',
        })
      }
      setScreenInitialized(true)
    })()
  }, [newsId])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
      publishedFrom: !!values.publishedFrom ? dateFromInputStringToSQL(values.publishedFrom) : null,
      publishedTo: convertStrToDate(values.publishedFrom, values.publishedTo)?.toFormat(SQL_DATE_TIME_FORMAT) ?? null,
      eventFrom: !!values.eventFrom ? dateFromInputStringToSQL(values.eventFrom) : null,
      eventTo: !!values.eventTo ? dateFromInputStringToSQL(values.eventTo) : null,
      thumbnailMediaId: values.thumbnail?.id ?? null,
    }
    try {
      let news = await api.news.save(data)
      navigate(`/dashboard/news/${news.id}`)
      enqueueSnackbar(t('News saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t(`Failed to save news: ${e.error ?? ''}`), {
        variant: 'error'
      })
    }
  }

  const onDelete = (e) => {
    // TODO
  }

  if (screenInitialized === false) {
    return (<Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>)
  }

  return (
    <Page title={t('News')}>
      <BaseForm
        id={isNaN(newsId) ? 0 : parseInt(newsId)}
        formTitle={t('News')}
        onDelete={onDelete}
        onSubmit={onSubmit}
        values={values}>
        <Grid item xs={12} md={9}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="news-title">{t('Title')}</InputLabel>
            <Input
              id="news-title"
              value={values.title}
              onChange={handleChange('title')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true}>{t('Thumbnail')}</InputLabel>
            <ImageFileUpload data={values.thumbnail ? [values.thumbnail] : []} onDataChanged={(mediaArray) => {
              handleChange('thumbnail') ({ target: { value: mediaArray[0] ?? null } })
            }} />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="select-news-type">{t('Type')}</InputLabel>
            <Select
              labelId="select-news-type"
              id="select-news-type"
              value={values.type}
              onChange={handleChange('type')}
            >
              <MenuItem value={'1'}>{t('News')}</MenuItem>
              <MenuItem value={'2'}>{t('Tutorials')}</MenuItem>
              <MenuItem value={'4'}>{t('Events')}</MenuItem>
              <MenuItem value={'8'}>{t('Showcase')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <InputLabel id={'select-selector-label'} shrink={true}>{t('Selector')}</InputLabel>
            <Select
              labelId="select-selector-label"
              id="select-selector"
              value={values.selectorId}
              onChange={handleChange('selectorId')}
            >
              <MenuItem key={`key-selector-null`} value={''}>{t('None')}</MenuItem>
              {selectors.map(sel => (
                <MenuItem key={`key-selector-${sel.id}`} value={sel.id}>{`${sel.brand.name} - ${sel.name}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Editor
            apiKey={TINY_MCE_API_KEY}
            value={values.content}
            onEditorChange={(newValue, editor) => { handleChange('content')({ target: { value: newValue } }) }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={2}>
            <TextField id="published-from" label={t('Published from')} type="datetime-local"
                       variant={'standard'} value={values.publishedFrom ?? ''} onChange={handleChange('publishedFrom')}
                       InputLabelProps={{ shrink: true, }}
            />
            <FormControl variant="standard" sx={{ mt: 2 }}>
              <InputLabel id="label-published-to" htmlFor="select-published-to">{t('Published to')}</InputLabel>
              <Select
                labelId="label-published-to"
                id="select-published-to"
                value={values.publishedTo}
                onChange={handleChange('publishedTo')}
              >
                <MenuItem value={PUBLISHED_TO_WEEK}>{t('1 week')}</MenuItem>
                <MenuItem value={PUBLISHED_TO_MONTH_1}>{t('1 month')}</MenuItem>
                <MenuItem value={PUBLISHED_TO_MONTH_3}>{t('3 months')}</MenuItem>
                <MenuItem value={PUBLISHED_TO_MONTH_6}>{t('6 months')}</MenuItem>
                <MenuItem value={PUBLISHED_TO_FOREVER}>{t('forever')}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={2}>
            <TextField id="event-from" label={t('Event from')} type="datetime-local" variant={'standard'}
                       value={values.eventFrom ?? ''} onChange={handleChange('eventFrom')}
                       InputLabelProps={{ shrink: true, }}
            />
            <TextField id="event-to" label={t('Event to')} type="datetime-local"
                       variant={'standard'} value={values.eventTo ?? ''} onChange={handleChange('eventTo')}
                       InputLabelProps={{ shrink: true,
            }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <RegionsCheckboxes
            handleProperty={'id'}
            selectedRegionCodes={values.regionIds}
            onSelectedRegionCodesChange={(regionIds) => {
              handleChange('regionIds')({ target: { value: regionIds } })
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField id="news-website" label={t('Website')} fullWidth variant="standard"
                     value={values.linkWebsite ?? ''} onChange={handleChange('linkWebsite')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField id="news-video" label={t('Video')} fullWidth variant="standard"
                     value={values.linkVideo ?? ''} onChange={handleChange('linkVideo')}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true}>{t('Media')}</InputLabel>
            <ImageFileUpload data={values.media} cols={4} multiple={true} onDataChanged={(mediaArray) => {
              handleChange('mediaIds') ({ target: { value: mediaArray.map(it => it.id) } })
            }} />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch
              checked={values.sendPushNotification}
              onChange={(e) => {
                handleChange('sendPushNotification')({ target: { value: !values.sendPushNotification } })
              }}
            />}
            label={t('Send push notification on save')} />
        </Grid>
      </BaseForm>
    </Page>
  )
}