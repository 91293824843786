import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Chip,
  Container,
  Link,
  Stack
}                          from '@mui/material'
import BaseTable              from '../components/base/BaseTable'
import { useApi }             from '../utils/api'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation }     from 'react-i18next'
import Page                   from '../components/Page'
import Typography             from '@mui/material/Typography'
import Iconify                from '../components/Iconify'
import useApps                from '../hooks/useApps'
import useRegions             from '../hooks/useRegions'
import Search                 from '../components/Search'

export default function Users() {

  const api = useApi()
  const { t } = useTranslation()
  const [apps] = useApps()
  const [regions] = useRegions()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: true },
    { field: 'name', headerName: t('Name'), flex: 2, filterable: false, renderCell: (cell) => {
      return (
        <Link
          component={RouterLink}
          size="small"
          to={`/dashboard/user/${cell.id}`}
        >
          {cell.row.name ? cell.row.name : '---'}
        </Link>
      )
      }, },
    { field: 'email', headerName: t('Email'), flex: 2, filterable: false },
    { field: 'role', headerName: t('Role'), flex: 1, filterable: false, valueGetter: (params) => t(params.row.role) },
    { field: '_id', headerName: t('Applications'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => {
        return (<Stack direction={'row'}>
          {(cell.row.appIds ?? []).map(appId => {
            let app = apps?.filter(it => it.id === appId).pop()
            return app && (<Chip key={app.id} avatar={<Avatar>{app.name?.toUpperCase()[0]}</Avatar>} label={app.name} />)
          })}
        </Stack>)
      }, },
    { field: 'region_id', headerName: t('Regions'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => {
        return (<Stack direction={'row'} spacing={1}>
          {(cell.row.regionIds ?? []).map(regionId => {
            let region = regions?.filter(it => it.id === regionId).pop()
            return region && (<Chip key={region.id} label={region.code} title={region.name} size="small" />)
          })}
        </Stack>)
      }, },
  ];

  return (<Page title={t('Users')}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>{t('Users')}</Typography>
        <Button variant="contained" component={RouterLink} to="/dashboard/user/create" startIcon={<Iconify icon="eva:plus-fill" />}>
          {t('New User')}
        </Button>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
        <Search query={query} onQueryChanged={(query) => setQuery(query)} title={t('Search user ...')} />
      </Stack>

      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize, orderBy) => {
          try {
            return await api.user.list(query, page, pageSize, ['regionIds', 'appIds'], orderBy)
          } catch (e) {
            console.log('Failed to load users', e)
            return []
          }
        }}
      />
    </Container>
  </Page>);
}