import Page                                          from '../components/Page'
import { Box, Button, Chip, Container, Link, Stack } from '@mui/material'
import Typography                                    from '@mui/material/Typography'
import { Link as RouterLink }                        from 'react-router-dom'
import Iconify                                       from '../components/Iconify'
import BaseTable                                     from '../components/base/BaseTable'
import React, { useEffect, useRef, useState }        from 'react'
import { t }                                         from 'i18next'
import { useApi }                                    from '../utils/api'
import { formatBytes }                               from '../utils/format'
import ExportSelectors                               from '../components/ExportSelectors'
import ImportSelector                                from '../components/ImportSelector'
import Search                                        from '../components/Search'
import { getSelectorSearch, setSelectorSearch }      from '../utils/storage'

const convertOrderByToSortModel = (orderBy) => {
  let result = []
  for(let i=0; i < orderBy.length; i++) {
    result.push({ field: orderBy[i][0], sort: orderBy[i][1] })
  }
  return result
}

export default function Selectors() {

  const api = useApi();
  const tableRef = useRef()
  const [filter, setFilter] = useState(getSelectorSearch())
  const [query, setQuery] = useState(filter?.query ?? '')

  useEffect(() => {
    setSelectorSearch(filter)
  }, [filter])


  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, filterable: false },
    { field: 'thumbnail', headerName: t('Thumbnail'), width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <img src={cell.row.thumbnail?.url ?? '/static/no-photo-available.png'}  alt={cell.row.name} />
        )
      }, },
    { field: 'brand.name', headerName: t('Brand'), width: 100, filterable: false, renderCell: (cell) => {
        return (
          <Typography variant={'caption'}>{cell.row.brand.name}</Typography>
        )
      }, },
    { field: 'name', headerName: t('Name'), flex: 2, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            size="small"
            to={`/dashboard/selectors/item/${cell.id}`}
          >
            {cell.row.name ? cell.row.name : '---'}
          </Link>
        )
      }, },
    { field: 'state', headerName: t('State'), width: 100, filterable: false, renderCell: (cell) => {
        let state = cell.row.state
        return (
          <Chip size={'small'}
                variant={'outlined'}
                color={(state === 'deleted' && 'error') || (state === 'draft' && 'warning') || 'primary'}
                label={(state === 'deleted' && t('Deleted')) || (state === 'draft' && t('Draft')) || t('Published')} />
        )
      }, },
    { field: 'size', headerName: t('Size'), width: 100, filterable: false, renderCell: (cell) => {
        return (
          <Typography variant={'caption'}>{formatBytes(cell.row.size)}</Typography>
        )
      }, },
  ];

  const loadCallback = async (query, page, pageSize, orderBy) => {
    if (orderBy?.length > 0 && orderBy[0].length > 0 && orderBy[0][0] === 'brand.name') {
      orderBy.push(['name', orderBy[0][1]])
    }
    setFilter({
      sortModel: convertOrderByToSortModel(orderBy),
      page: page,
      pageSize: pageSize,
      query: query,
    })
    try {
      return await api.selector.list(null, query, page, pageSize, ['thumbnail', 'brand'], orderBy)
    } catch (e) {
      console.log('Failed to load selectors', e)
      return []
    }
  }

  return (<Page title={t('Selectors')}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={1}>
        <Typography variant="h4" gutterBottom>{t('Selectors')}</Typography>
        <Box flex={1} />
        <ExportSelectors selectorIds={() => tableRef.current.getData().map(it => it.id)} />
        <ImportSelector />
        <Button variant="contained" component={RouterLink} to="/dashboard/selectors/item/create" startIcon={<Iconify icon="eva:plus-fill" />}>
          {t('New selector')}
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent={'space-between'} mb={2}>
        <Search query={query} onQueryChanged={(query) => setQuery(query)} title={t('Search selector...')} />
      </Stack>
      <BaseTable
        ref={tableRef}
        columns={columns}
        loadCallback={loadCallback}
        query={query}
        initialSortModel={filter?.sortModel ?? [{ field: 'id', sort: 'desc' }]}
        initialPage={filter?.page ?? 0}
        initialPageSize={filter?.pageSize ?? 100}
      />
    </Container>
  </Page>);

}