import PropTypes                   from 'prop-types'
import BaseTable                   from '../../components/base/BaseTable'
import React, { useRef, useState } from 'react'
import { t }                       from 'i18next'
import { useApi }                  from '../../utils/api'
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Link,
  Stack
}                                  from '@mui/material'
import Typography                  from '@mui/material/Typography'
import BaseLangSelect              from '../../components/base/BaseLangSelect'
import Iconify                     from '../../components/Iconify'
import { TEXTURES }                from '../../utils/consts'
import { lab2rgb }                 from '../../utils/colors'

export default function SelectorFoils(props) {

  const { selectorId, onFoilClicked, reloadCounter } = props

  const api = useApi()
  const [lang, setLang] = useState('')
  const filteredFoilIds = useRef([])

  const onFoilLinkClicked = (foil) => (e) => {
    e.preventDefault()
    if (foil === null) {
      onFoilClicked(null, [])
    } else {
      if (!!filteredFoilIds.current) {
        onFoilClicked(filteredFoilIds.current.indexOf(foil.id), filteredFoilIds.current)
      }
    }
  }

  const foilColumns = [
    { field: 'id', headerName: t('ID'), width: 100, renderCell: (cell) => {
        return (
          <Link
            href={"#"}
            size="small"
            onClick={onFoilLinkClicked(cell.row)}
          >
            {cell.row.id}
          </Link>
        )
    }, },
    { field: 'state', headerName: t('State'), width: 100, renderCell: (cell) => {
        let state = cell.row.state
        return (
          <Chip size={'small'}
                variant={'outlined'}
                color={(state === 'deleted' && 'error') || (state === 'draft' && 'warning') || 'primary'}
                label={(state === 'deleted' && t('Deleted')) || (state === 'draft' && t('Draft')) || t('Published')} />
        )
      }, },
    { field: 'material', headerName: t('Material'), width: 100, renderCell: (cell) => {
        return (
          <Typography variant={'subtitle'}>{cell.row.material}</Typography>
        )
      }, },
    { field: 'thumbnail', headerName: t('Thumbnail'), width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        let thumbnail = cell.row.textures?.filter(t => t.type === TEXTURES.color_fan_thumbnail)?.pop()
        if (thumbnail) {
          return <img src={thumbnail.media.url} alt={thumbnail.type} />
        }
        thumbnail = cell.row.textures?.filter(t => t.type === TEXTURES.color_card_thumbnail)?.pop()
        if (thumbnail) {
          return <img src={thumbnail.media.url} alt={thumbnail.type} />
        }
        let rgb = lab2rgb(cell.row.lab)
        return <Box sx={{ width: '100%', height: '100%', bgcolor: `rgb(${rgb[0]},${rgb[1]},${rgb[2]})` }} />
      }, },
    { field: 'name', headerName: t('Name'), flex: 2,
      sortComparator: (name1, name2) => {
        let n1 = name1[lang] ?? ''
        let n2 = name2[lang] ?? ''
        return n1.localeCompare(n2)
      },
      renderCell: (cell) => {
        return (
          <Link
            href={"#"}
            size="small"
            onClick={onFoilLinkClicked(cell.row)}
          >
            {cell.row.name[lang] ?? (`-- ${t('not provided')} --`)}
          </Link>
        )
      }, },
  ]

  const loadFoilsCallback = async (query, page, pageSize) => {
    if (!selectorId) {
      return []
    }
    try {
      return await api.foil.list(selectorId, query, page, pageSize, ['textures'], ['position', 'ASC'])
    } catch (e) {
      console.log('Failed to load selectors', e)
      return []
    }
  }

  return (<>
    <Container>
      <Divider sx={{ my: 5 }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4" gutterBottom>{t('Selector foils')}</Typography>
        <Stack direction={'row'} spacing={2}>
          <BaseLangSelect onLangChanged={(lang) => {
            setLang(lang?.code)
          }}/>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={onFoilLinkClicked(null)}>
            {t('New foil')}
          </Button>
        </Stack>
      </Stack>
      <BaseTable columns={foilColumns}
                 loadCallback={loadFoilsCallback}
                 sortingMode={'client'}
                 forceReloadCounter={reloadCounter}
                 onStateChange={e => {
                   filteredFoilIds.current = e.sorting.sortedRows.filter(id => !e.filter.filteredRowsLookup.hasOwnProperty(id) || e.filter.filteredRowsLookup[id])
                 }}
                 initialSortModel={[{ field: 'position', sort: 'asc' }]} />
    </Container>
  </>)
}

SelectorFoils.propTypes = {
  selectorId: PropTypes.number.isRequired,
  onFoilClicked: PropTypes.func.isRequired,
  reloadCounter: PropTypes.number.isRequired,
}