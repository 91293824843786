import { useCallback, useEffect, useState }   from 'react'
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import Iconify                                from '../../components/Iconify'
import { t }                                  from 'i18next'
import useLangs                               from '../../hooks/useLangs'
import PropTypes                              from 'prop-types'

// ----------------------------------------------------------------------

export default function BaseLangSelect(props) {

  const { onLangChanged } = props

  const [open, setOpen] = useState(null);
  const handleOpen = (event) => setOpen(event.currentTarget)
  const handleClose = () => setOpen(null)

  const [langs, defaultLang] = useLangs()
  const [selectedLang, setSelectedLang] = useState(null)

  useEffect(() => {
    if (defaultLang && selectedLang == null) {
      setSelectedLang(defaultLang)
    }
  }, [defaultLang])

  useEffect(() => {
    onLangChanged(selectedLang)
  }, [selectedLang])

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        {t('Language')}:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {selectedLang?.code ?? ''}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {langs?.map((option) => (
          <MenuItem
            key={option.id}
            selected={option.id === selectedLang?.id}
            onClick={() => {
              setSelectedLang(option)
              handleClose()
            }}
            sx={{ typography: 'body2' }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

BaseLangSelect.propTypes = {
  onLangChanged: PropTypes.func.isRequired,
}